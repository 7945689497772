import React from 'react';
import { Browser } from '@capacitor/browser';
import { CloseIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Grid,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { DialogProps } from 'clipsal-cortex-types/src/common/chakra-extension-types';

import { useViewportType } from '../../../common/hooks/use-viewport-type';
import { DEVICE_TYPE_TO_DISPLAY_DATA, getOperatingStateUIConfig } from '../../profile/system-details/constants';
import { checkIfOperatingStateErrorCanBeIgnored } from '../../profile/system-details/system-details-helpers';
import { SupportModal } from '../../support/SupportModal';
import { selectSite } from '../siteSlice';
import { HYBRID_INVERTER_TROUBLESHOOTING_ARTICLE_URL, METER_TROUBLESHOOTING_ARTICLE_URL } from './constants';

type DeviceAlertInfoDialogProps = DialogProps;

export function DeviceAlertInfoDialog({ isOpen, onClose }: DeviceAlertInfoDialogProps) {
  const { isOpen: isSupportDialogOpen, onOpen: onOpenSupportDialog, onClose: onCloseSupportDialog } = useDisclosure();
  const commonProps = { isOpen, onClose, onOpenSupportDialog };
  const { isDesktopViewport } = useViewportType();

  return (
    <>
      {isDesktopViewport ? <DeviceAlertInfoModal {...commonProps} /> : <DeviceAlertInfoBottomDrawer {...commonProps} />}
      {isSupportDialogOpen && <SupportModal isOpen={isSupportDialogOpen} onClose={onCloseSupportDialog} />}
    </>
  );
}

type CommonDeviceAlertInfoDialogProps = {
  onOpenSupportDialog: () => void;
} & DialogProps;

function DeviceAlertInfoModal({ isOpen, onClose, onOpenSupportDialog }: CommonDeviceAlertInfoDialogProps) {
  return (
    <Modal scrollBehavior={'inside'} size={'xl'} isCentered={true} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent data-testid="device-alert-info-modal">
        <ModalHeader as={Center}>Device Alert Information</ModalHeader>
        <ModalCloseButton data-testid="device-alert-info-modal-close-btn" />
        <ModalBody>
          <DeviceAlertList onOpenSupportDialog={onOpenSupportDialog} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function DeviceAlertInfoBottomDrawer({ isOpen, onClose, onOpenSupportDialog }: CommonDeviceAlertInfoDialogProps) {
  const drawerHeaderBorderColor = useColorModeValue('dusk005.500', 'borderGrey.500');

  return (
    <Drawer onClose={onClose} placement={'bottom'} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent data-testid="device-alert-info-drawer">
        <DrawerHeader borderColor={drawerHeaderBorderColor}>
          <Center position="relative">
            <Button
              data-testid="device-alert-info-drawer-close-btn"
              variant="ghost"
              onClick={onClose}
              left={0}
              position="absolute"
              size="sm"
            >
              <CloseIcon w={[3, 4]} h={[3, 4]} />
            </Button>
            <Text>Device Alert Information</Text>
          </Center>
        </DrawerHeader>
        <DrawerBody p={4}>
          <DeviceAlertList onOpenSupportDialog={onOpenSupportDialog} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

function DeviceAlertList({ onOpenSupportDialog }: { onOpenSupportDialog: () => void }) {
  const iconProperty = useColorModeValue('iconLight', 'iconDark');
  const { devices } = useSelector(selectSite);

  return (
    <Box>
      <Alert mb={3} status="info">
        <AlertIcon />
        <Box>
          <Text>
            When there is an issue with one of your devices, the data you see in the Cortex app will be inaccurate.
          </Text>
          <Text mt={1}>
            <b>NOTE:</b> Data can take a few hours to be reflected in the app after troubleshooting.
          </Text>
        </Box>
      </Alert>

      <Heading size="md">Device Alerts</Heading>
      {devices!
        .filter((device) => device.operating_state && ![null, 'NORMAL'].includes(device.operating_state))
        .map((device, i) => {
          const {
            device_type: deviceType,
            model,
            manufacturer_name: manufacturerName,
            operating_state: operatingState,
          } = device;

          const canIgnoreError = checkIfOperatingStateErrorCanBeIgnored(device);
          const operatingStateConfig = operatingState && getOperatingStateUIConfig(operatingState);

          return (
            <Grid w={'100%'} gridColumnGap={2} my={2} pt={2} key={`device-${i}`} templateColumns={'50px 7fr 3fr'}>
              <Center>
                <Image src={DEVICE_TYPE_TO_DISPLAY_DATA[deviceType][iconProperty]} alt={'Device icon'} />
              </Center>

              <Flex justify={'center'} direction={'column'}>
                <Heading size={'sm'}>{DEVICE_TYPE_TO_DISPLAY_DATA[deviceType].title}</Heading>
                <Text fontSize={'sm'} color={'textGrey.500'}>
                  {manufacturerName} {model}
                </Text>
                {!canIgnoreError && operatingStateConfig && (
                  <Text fontSize={'sm'} color={operatingStateConfig.color}>
                    {operatingStateConfig.text}
                  </Text>
                )}
              </Flex>

              <Center>
                <Button
                  rounded={20}
                  onClick={async () => {
                    /* istanbul ignore else -- @preserve */
                    if (device.device_type === 'METER') {
                      /* istanbul ignore else -- @preserve */
                      if (device.operating_state === 'SUBSCRIPTION_EXPIRED') {
                        onOpenSupportDialog();
                      } else {
                        await Browser.open({
                          url: METER_TROUBLESHOOTING_ARTICLE_URL,
                        });
                      }
                    } else {
                      await Browser.open({
                        url: HYBRID_INVERTER_TROUBLESHOOTING_ARTICLE_URL,
                      });
                    }
                  }}
                  colorScheme="dusk100"
                >
                  {device.operating_state !== 'SUBSCRIPTION_EXPIRED' ? 'How to resolve' : 'Contact support'}
                </Button>
              </Center>
            </Grid>
          );
        })}
    </Box>
  );
}

import React, { StrictMode, useMemo } from 'react';
import { Center, ChakraProvider, ColorModeScript, Heading } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { cloneDeep } from 'lodash';
import { Provider, useSelector } from 'react-redux';

import { NetworkStatusProvider } from 'clipsal-cortex-ui/src/components/NetworkStatusProvider';
import { ServiceWorkerProvider } from 'clipsal-cortex-utils/src/providers/service-worker-provider';

import { App } from './App';
import { store } from './app/store';
import { VersionCheck } from './app/VersionCheck';
import { PullToRefresh } from './common/components/pull-to-refresh/PullToRefresh';
import { selectSite } from './features/site/siteSlice';

export const AppProvider = () => {
  return (
    <StrictMode>
      <Sentry.ErrorBoundary
        fallback={
          <Center h={'100vh'} w={'100%'}>
            <Heading textAlign={'center'}>
              An error occurred. Please refresh the page to continue. If this error persists, try closing and re-opening
              the app.
            </Heading>
          </Center>
        }
      >
        <NetworkStatusProvider>
          <Provider store={store}>
            <ThemedAppProvider />
          </Provider>
        </NetworkStatusProvider>
      </Sentry.ErrorBoundary>
    </StrictMode>
  );
};

function ThemedAppProvider() {
  const { theme } = useSelector(selectSite);
  const siteTheme = useMemo(() => {
    // chakra mutates theme internally but redux does not like that,
    // so we need to clone it before providing it to chakra
    return cloneDeep(theme);
  }, [theme]);

  return (
    <ChakraProvider theme={siteTheme}>
      <ServiceWorkerProvider>
        <ColorModeScript />
        <PullToRefresh>
          <VersionCheck>
            <App />
          </VersionCheck>
        </PullToRefresh>
      </ServiceWorkerProvider>
    </ChakraProvider>
  );
}

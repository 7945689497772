import React, { useEffect, useState } from 'react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Button, Center, Heading, Image, Link, Text, useTheme } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

export function SubscriptionSuccess() {
  return (
    <>
      <Heading textAlign="center" size="lg" mb={2}>
        You're ready to go!
      </Heading>

      <Center py={3}>
        <AnimatedTick />
      </Center>

      <Text textAlign={'center'} mt={4}>
        Get ready to start saving!
      </Text>
      <Text textAlign={'center'} mb={4}>
        For the best mobile experience, download the Clipsal Cortex app on your phone
      </Text>
      <Center flexDir={['column', 'column', 'row']}>
        <Link href="https://apps.apple.com/app/id1488273491">
          <Image
            maxWidth="200px"
            src="https://clipsalsolar-website-images.s3-ap-southeast-2.amazonaws.com/itunes-app-store-logo.png"
            alt="App store image"
          />
        </Link>

        <Link href="https://play.google.com/store/apps/details?id=com.clipsalsolar.pulse">
          <Image
            maxWidth="228px"
            src="https://clipsalsolar-website-images.s3-ap-southeast-2.amazonaws.com/play-store-badge.png"
            alt="Play store image"
          />
        </Link>
      </Center>

      <Center flexDirection="column">
        <Heading my={4} size="md">
          OR
        </Heading>

        <Button mx={'auto'} as={RouterLink} to={'/'} mt={4} rounded={20} colorScheme="dusk100">
          Continue to the Cortex web app <ArrowForwardIcon w={5} h={5} ml={2} />
        </Button>
      </Center>
    </>
  );
}

function AnimatedTick() {
  const [state, setState] = useState({
    circle: { opacity: 0, strokeDashoffset: 130, strokeDasharray: 130 },
    tick: { opacity: 0, strokeDashoffset: 50, strokeDasharray: 50 },
  });
  const theme = useTheme();

  useEffect(() => {
    const timer = setTimeout(() => {
      setState((prevState) => ({
        circle: {
          ...prevState.circle,
          opacity: 1,
          strokeDashoffset: 0,
        },
        tick: {
          ...prevState.tick,
          opacity: 1,
          strokeDashoffset: 0,
        },
      }));
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box
      w={'30%'}
      as="svg"
      version="1.1"
      id="tick"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 37 37"
      enableBackground="new 0 0 37 37"
    >
      <path
        opacity={state.circle.opacity}
        fill="none"
        stroke={theme.colors.primaryBranding[500]}
        strokeDashoffset={state.circle.strokeDashoffset}
        strokeDasharray={state.circle.strokeDasharray}
        strokeWidth="3"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        style={{
          transition: 'all 1s',
        }}
        // eslint-disable-next-line max-len
        d="M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z"
      />
      <polyline
        fill="none"
        opacity={state.tick.opacity}
        stroke={theme.colors.primaryBranding[500]}
        strokeWidth="3"
        strokeDashoffset={state.tick.strokeDashoffset}
        strokeDasharray={state.tick.strokeDasharray}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        style={{
          transition: 'stroke-dashoffset 1s 0.5s ease-out',
        }}
        points="11.6,20 15.9,24.2 26.4,13.8 "
      />
    </Box>
  );
}

import React, { Children, isValidElement, PropsWithChildren, ReactNode, useMemo } from 'react';
import {
  Button,
  CloseButton,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerContentProps,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Modal,
  ModalBody,
  ModalBodyProps,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import { DialogProps } from 'clipsal-cortex-types/src/common/chakra-extension-types';

import { useViewportType } from '../hooks/use-viewport-type';

type Props = PropsWithChildren<
  {
    title: string;
    showCloseButton?: boolean;
    ['data-testid']?: string;
    drawerContentProps?: DrawerContentProps;
    modalContentProps?: ModalContentProps;
    drawerBodyProps?: ModalBodyProps;
  } & DialogProps
>;

/**
 * This component renders a drawer on mobile and a modal on desktop, in order to provide a pleasant dialog experience in both cases.
 * It automatically handles margins and spacing for different screen sizes.
 * @param props - The props for the dialog
 * @returns The dialog component
 */
export function Dialog(props: Props) {
  const { isDesktopViewport } = useViewportType();
  const DialogComponent = isDesktopViewport ? DialogModal : DialogDrawer;

  return <DialogComponent {...props} />;
}

function DialogBody({ children }: { children: ReactNode }) {
  return <>{children}</>;
}

function DialogFooter({ children }: { children: ReactNode }) {
  return <>{children}</>;
}

Dialog.Body = DialogBody;
Dialog.Footer = DialogFooter;

function DialogDrawer(props: Props) {
  const { onClose, isOpen, title, children, drawerContentProps, drawerBodyProps, showCloseButton = false } = props;

  const BodyComponent = useMemo(
    () => Children.toArray(children).find((child) => isValidElement(child) && child.type === DialogBody),
    [children]
  );
  const FooterComponent = useMemo(
    () => Children.toArray(children).find((child) => isValidElement(child) && child.type === DialogFooter),
    [children]
  );

  return (
    <Drawer onClose={onClose} placement={'bottom'} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent {...drawerContentProps} data-testid={props['data-testid']}>
        <DrawerHeader pt={3} pb={0}>
          <Flex fontSize={'17px'} alignItems="center" pos="relative">
            {showCloseButton && (
              <Button
                data-testid={props['data-testid'] ? `${props['data-testid']}-close-btn` : 'dialog-close-btn'}
                variant="link"
                color="primaryBrandingStatic.500"
                position="absolute"
                top="50%"
                left="20px"
                transform="translate(-50%, -50%)"
                fontWeight="normal"
                onClick={onClose}
              >
                Close
              </Button>
            )}

            <Text mx="auto">{title}</Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody px={0} pt={3} pb={5} {...drawerBodyProps}>
          {BodyComponent ? BodyComponent : children}
        </DrawerBody>
        {FooterComponent && <ModalFooter>{FooterComponent}</ModalFooter>}
      </DrawerContent>
    </Drawer>
  );
}

function DialogModal(props: Props) {
  const { onClose, isOpen, title, children, modalContentProps, showCloseButton = false } = props;

  const BodyComponent = useMemo(
    () => Children.toArray(children).find((child) => isValidElement(child) && child.type === DialogBody),
    [children]
  );
  const FooterComponent = useMemo(
    () => Children.toArray(children).find((child) => isValidElement(child) && child.type === DialogFooter),
    [children]
  );

  return (
    <Modal
      scrollBehavior="inside"
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isCentered={true}
      size={'3xl'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent {...modalContentProps} data-testid={props['data-testid']} rounded={20} pb={5}>
        <ModalHeader>{title}</ModalHeader>
        {showCloseButton && (
          <CloseButton
            data-testid={props['data-testid'] ? `${props['data-testid']}-close-btn` : 'dialog-close-btn'}
            pos={'absolute'}
            top={2}
            right={3}
            onClick={onClose}
          />
        )}
        <ModalBody px={6}> {BodyComponent ? BodyComponent : children}</ModalBody>
        {FooterComponent && <ModalFooter>{FooterComponent}</ModalFooter>}
      </ModalContent>
    </Modal>
  );
}

/* eslint-disable react-refresh/only-export-components */
import React, { lazy, ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { OTPVerification } from './features/auth/new-user-journey/OTPVerification';
import { SelectSubscriptionPlan } from './features/auth/new-user-journey/SelectSubscriptionPlan';
import { SubscriptionSuccess } from './features/auth/new-user-journey/SubscriptionSuccess';
import { AmberIntegration } from './features/bills/amber/AmberIntegration';
import { BillsHome } from './features/bills/BillsHome';
import { ComparePlans } from './features/bills/energy-plan/compare-plans/ComparePlans';
import { MobileViewPlanDetail } from './features/bills/energy-plan/compare-plans/MobileViewPlanDetail';
import { MobileViewPlans } from './features/bills/energy-plan/compare-plans/MobileViewPlans';
import { BasicDetailsForm } from './features/bills/tariffs/basic-details/BasicDetailsForm';
import { FlatTariffForm } from './features/bills/tariffs/rates/flat/FlatTariffForm';
import { RealTimeTariffForm } from './features/bills/tariffs/rates/real-time/RealTimeTariffForm';
import { TieredTariffForm } from './features/bills/tariffs/rates/tiered/TieredTariffForm';
import { TOURateForm } from './features/bills/tariffs/rates/time-of-use/TOUTariffForm';
import { Review } from './features/bills/tariffs/review/Review';
import { TariffRoutes } from './features/bills/tariffs/TariffRoutes';
import { ActivityLog } from './features/dashboard/ActivityLog';
import { PowerCycleInstructions } from './features/ev-chargers/schneider-charge/PowerCycleInstructions';
import { ScanWifiQRCode } from './features/ev-chargers/schneider-charge/ScanWifiQRCode';
import { SchneiderChargeActivate } from './features/ev-chargers/schneider-charge/SchneiderChargeActivate';
import { SchneiderChargeEnterPin } from './features/ev-chargers/schneider-charge/SchneiderChargeEnterPin';
import { SchneiderChargeHomeWifiDetails } from './features/ev-chargers/schneider-charge/SchneiderChargeHomeWifiDetails';
import { SchneiderChargeNewPin } from './features/ev-chargers/schneider-charge/SchneiderChargeNewPin';
import { SchneiderChargeRoutes } from './features/ev-chargers/schneider-charge/SchneiderChargeRoutes';
import { SetupGenericFail } from './features/ev-chargers/schneider-charge/SetupGenericFail';
import { SetupSuccess } from './features/ev-chargers/schneider-charge/SetupSuccess';
import { SetupUnavailableFail } from './features/ev-chargers/schneider-charge/SetupUnavailableFail';
import { StartSetup } from './features/ev-chargers/schneider-charge/StartSetup';
import { About, AboutOutlet } from './features/profile/about/About';
import { TermsAndConditions } from './features/profile/about/TermsAndConditions';
import { AccountDetails } from './features/profile/AccountDetails';
import { Contact } from './features/profile/contact/Contact';
import { InstallationIssues } from './features/profile/contact/InstallationIssues';
import { Integrations } from './features/profile/integrations/Integrations';
import { ManageTeslaIntegration } from './features/profile/integrations/tesla/ManageTeslaIntegration';
import { VehiclePairingTroubleshooting } from './features/profile/integrations/tesla/VehiclePairingTroubleshooting';
import { ProfileOutlet } from './features/profile/Profile';
import { OptimisationSettings } from './features/profile/smart-save/OptimisationSettings';
import { SmartSaveSettings } from './features/profile/smart-save/SmartSaveSettings';
import { SCHNEIDER_CHARGE_PATH } from './features/profile/system-details/constants';
import { SystemDetails, SystemDetailsOutlet } from './features/profile/system-details/SystemDetails';
import { SiteRedirect } from './features/site/SiteRedirect';

// All first level routes should be lazy loaded.
const Login = lazy(() => import('./features/auth/login/Login').then((module) => ({ default: module.Login })));
const Logout = lazy(() => import('./features/auth/logout/Logout').then((module) => ({ default: module.Logout })));
const NewUserJourney = lazy(() =>
  import('./features/auth/new-user-journey/NewUserJourney').then((module) => ({ default: module.NewUserJourney }))
);
const RouteNotFound = lazy(() =>
  import('./common/components/route/RouteNotFound').then((module) => ({ default: module.RouteNotFound }))
);
const Site = lazy(() => import('./features/site/Site').then((module) => ({ default: module.Site })));
const Sites = lazy(() => import('./features/sites/Sites').then((module) => ({ default: module.Sites })));
const SubscriptionExpired = lazy(() =>
  import('./features/auth/SubscriptionExpired').then((module) => ({ default: module.SubscriptionExpired }))
);
const TeslaOAuthRedirect = lazy(() =>
  import('./features/auth/TeslaOAuthRedirect').then((module) => ({ default: module.TeslaOAuthRedirect }))
);
const Profile = lazy(() => import('./features/profile/Profile').then((module) => ({ default: module.Profile })));

// Side second level routes should be lazy loaded.
const SiteBills = lazy(() => import('./features/bills/SiteBills').then((module) => ({ default: module.SiteBills })));
const SiteLive = lazy(() => import('./features/live/SiteLive').then((module) => ({ default: module.SiteLive })));
const SiteNotifications = lazy(() =>
  import('./features/notifications/SiteNotifications').then((module) => ({ default: module.SiteNotifications }))
);
const SiteSavings = lazy(() =>
  import('./features/savings/SiteSavings').then((module) => ({ default: module.SiteSavings }))
);
const SiteDashboard = lazy(() =>
  import('./features/dashboard/Dashboard').then((module) => ({ default: module.Dashboard }))
);
const SiteUsage = lazy(() => import('./features/usage/SiteUsage').then((module) => ({ default: module.SiteUsage })));

export interface Route {
  path: string;
  element: ReactElement;
  children?: Route[];
  isPublic?: boolean;
}

export const routes: Route[] = [
  {
    element: <SiteRedirect />,
    path: '/',
    isPublic: true,
  },
  {
    element: <Site />,
    path: '/site/:id',
    children: [
      {
        element: <SiteSavings />,
        path: 'savings',
      },
      {
        element: <Outlet />,
        path: 'dashboard',
        children: [
          {
            element: <SiteDashboard />,
            path: '',
          },
          {
            element: <ActivityLog />,
            path: 'activity-logs',
          },
        ],
      },
      {
        element: <SiteUsage />,
        path: 'usage',
      },
      {
        element: <SiteBills />,
        path: 'bills',
        children: [
          {
            element: <BillsHome />,
            path: '',
          },
          {
            element: <ComparePlans />,
            path: 'compare_plans',
          },
          {
            element: <MobileViewPlans />,
            path: 'compare_plans/view_all',
          },
          {
            element: <MobileViewPlanDetail />,
            path: 'compare_plans/view_plan/:planID',
          },
          {
            element: <AmberIntegration />,
            path: 'amber_integration',
          },
          {
            element: <TariffRoutes />,
            path: 'tariffs/:tariffId',
            children: [
              {
                element: <Navigate to="basic-details" />,
                path: '',
              },
              {
                element: <BasicDetailsForm />,
                path: 'basic-details',
              },
              {
                element: <TOURateForm />,
                path: 'tou-rate-configuration',
              },
              {
                element: <RealTimeTariffForm />,
                path: 'real-time-rate-configuration',
              },
              {
                element: <FlatTariffForm />,
                path: 'flat-rate-configuration',
              },
              {
                element: <TieredTariffForm />,
                path: 'tiered-rate-configuration',
              },
              {
                element: <Review />,
                path: 'review',
              },
            ],
          },
        ],
      },
      {
        element: <SiteLive />,
        path: 'live',
      },
      {
        element: <SiteNotifications />,
        path: 'notifications',
      },
      {
        element: <ProfileOutlet />,
        path: 'profile',
        children: [
          {
            element: <Profile />,
            path: '',
          },
          {
            element: <AccountDetails />,
            path: 'account-details',
          },
          {
            element: <Integrations />,
            path: 'integrations',
          },
          {
            element: <Outlet />,
            path: 'manage-tesla-integration',
            children: [
              {
                element: <ManageTeslaIntegration />,
                path: '',
              },
              {
                element: <VehiclePairingTroubleshooting />,
                path: 'vehicle-pairing-troubleshooting',
              },
            ],
          },
          {
            element: <Outlet />,
            path: 'smart-save',
            children: [
              {
                path: '',
                element: <SmartSaveSettings />,
              },
              {
                path: 'optimisation-settings',
                element: <OptimisationSettings />,
              },
            ],
          },
          {
            element: <SystemDetailsOutlet />,
            path: 'system-details',
            children: [
              {
                path: '',
                element: <SystemDetails />,
              },
              {
                element: <SchneiderChargeRoutes />,
                path: SCHNEIDER_CHARGE_PATH,
                children: [
                  {
                    path: '',
                    element: <Navigate to="start" />,
                  },
                  {
                    path: 'start',
                    element: <StartSetup />,
                  },
                  {
                    path: 'power_cycle_instructions',
                    element: <PowerCycleInstructions />,
                  },
                  {
                    path: 'scan_wifi_qr_code',
                    element: <ScanWifiQRCode />,
                  },
                  {
                    path: 'enter_pin',
                    element: <SchneiderChargeEnterPin />,
                  },
                  {
                    path: 'new_pin',
                    element: <SchneiderChargeNewPin />,
                  },
                  {
                    path: 'home_wifi_details',
                    element: <SchneiderChargeHomeWifiDetails />,
                  },
                  {
                    path: 'activate',
                    element: <SchneiderChargeActivate />,
                  },
                  {
                    path: 'setup_success',
                    element: <SetupSuccess />,
                  },
                  {
                    path: 'setup_generic_fail',
                    element: <SetupGenericFail />,
                  },
                  {
                    path: 'setup_unavailable_fail',
                    element: <SetupUnavailableFail />,
                  },
                ],
              },
            ],
          },
          {
            element: <Outlet />,
            path: 'contact',
            children: [
              {
                element: <Contact />,
                path: '',
              },
              {
                element: <InstallationIssues />,
                path: 'installation-issues',
              },
            ],
          },
          {
            element: <AboutOutlet />,
            path: 'about',
            children: [
              {
                element: <About />,
                path: '',
              },
              {
                element: <TermsAndConditions />,
                path: 'terms-and-conditions',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    element: <Sites />,
    path: '/sites',
  },
  {
    element: <Login />,
    path: '/login',
    isPublic: true,
  },
  {
    element: <Logout />,
    path: '/logout',
    isPublic: true,
  },
  {
    element: <NewUserJourney />,
    path: '/new_user_journey',
    isPublic: true,
    children: [
      {
        element: <SelectSubscriptionPlan />,
        path: 'select_subscription_plan',
        isPublic: true,
      },
      {
        element: <SubscriptionSuccess />,
        path: 'success',
        isPublic: true,
      },
      {
        element: <OTPVerification />,
        path: 'otp_verify',
        isPublic: true,
      },
    ],
  },
  {
    element: <SubscriptionExpired />,
    path: '/subscription_expired',
  },
  {
    element: <TeslaOAuthRedirect />,
    path: '/tesla',
    isPublic: true,
  },
  {
    element: <RouteNotFound />,
    path: '*',
  },
];

import React from 'react';
import { Checkbox } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';

import { InfoPopover } from 'clipsal-cortex-ui/src/components/InfoPopover';

import { TariffFormData } from '../types';

export function GSTInclusionCheckbox() {
  const { control } = useFormContext<TariffFormData>();

  return (
    <Controller
      name="ratesAreInclusiveOfGST"
      control={control}
      render={({ field: { onChange, value, ref } }) => (
        <Checkbox data-testid="gst-inclusion-checkbox" onChange={onChange} ref={ref} mb={4} isChecked={value}>
          My rates are inclusive of GST{' '}
          <InfoPopover
            triggerContainerProps={{
              onClick: (e) => {
                // Ensure clicking the popover trigger doesn't check or uncheck the checkbox
                e.preventDefault();
              },
            }}
          >
            GST is charged on most usage rates and will be automatically added. If the rates you enter into this form
            already include GST, check this box.
          </InfoPopover>
        </Checkbox>
      )}
    />
  );
}

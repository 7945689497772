import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../../app/store';

export type SchneiderChargeSetupState = {
  siteDeviceId: number | null;
  chargerId: string | null;
  bearerToken: string | null;
};

const initialState: SchneiderChargeSetupState = {
  siteDeviceId: null,
  chargerId: null,
  bearerToken: null,
};

export const schneiderChargeSetupSlice = createSlice({
  name: 'schneiderChargeSetup',
  initialState,
  reducers: {
    addSiteDeviceId: (state, action: PayloadAction<number>) => {
      return { ...state, siteDeviceId: action.payload };
    },
    addChargerId: (state, action: PayloadAction<string>) => {
      return { ...state, chargerId: action.payload };
    },
    addBearerToken: (state, action: PayloadAction<string>) => {
      return { ...state, bearerToken: action.payload };
    },
    resetSchneiderChargeSetup: () => {
      return { ...initialState };
    },
  },
});

export const { resetSchneiderChargeSetup, addSiteDeviceId, addChargerId, addBearerToken } =
  schneiderChargeSetupSlice.actions;

export const selectSiteDeviceId = (state: RootState) => {
  return state.schneiderChargeSetup.siteDeviceId;
};

export const selectChargerId = (state: RootState) => {
  return state.schneiderChargeSetup.chargerId;
};

export const selectBearerToken = (state: RootState) => {
  return state.schneiderChargeSetup.bearerToken;
};

export const { reducer: schneiderChargeSetupReducer } = schneiderChargeSetupSlice;

import React, { useCallback, useEffect, useRef } from 'react';
import { Capacitor } from '@capacitor/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { Icon } from '@chakra-ui/icon';
import { Box, Center } from '@chakra-ui/react';
import { BsArrowClockwise } from 'react-icons/bs';

import { DIRECTION, isTreeScrollable } from './is-scrollable';

interface PullToRefreshProps {
  children: JSX.Element;
  pullDownThreshold?: number;
  maxPullDownDistance?: number;
  resistance?: number;
}

/**
 * `PullToRefresh` is a component that attaches touch listeners to native
 * devices and performs window reload on pull down to specified threshold.
 *
 * @prop `children` - children wrapped in the component
 * @prop `pullDownThreshold` - pull distance in px to trigger reload
 * @prop `maxPullDownDistance` - max pull down distance in px
 * @prop `resistance` - manipulates pull down resistance
 */
export const PullToRefresh = ({
  children,
  pullDownThreshold = 180,
  maxPullDownDistance = 210,
  resistance = 1.8,
}: PullToRefreshProps) => {
  const childRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const pullDownContainerRef = useRef<HTMLDivElement>(null);
  const pullDownIconContainerRef = useRef<HTMLDivElement>(null);
  const pullDownIconRef = useRef<HTMLDivElement>(null);
  const isDragging = useRef<boolean>(false);
  const startY = useRef<number>(0);
  const currentY = useRef<number>(0);
  /* istanbul ignore next -- @preserve */
  const isNativeDevice = Capacitor.isNativePlatform();

  const initContainer = (): void => {
    requestAnimationFrame(() => {
      // Adding checks to prevent null errors
      if (parentRef.current) {
        parentRef.current.style.height = 'auto';
        parentRef.current.style.overflowY = 'auto';
      }
      if (pullDownContainerRef.current) pullDownContainerRef.current.style.top = '-30px';
      if (pullDownIconContainerRef.current) pullDownIconContainerRef.current.style.transform = 'rotate(0deg)';
    });
  };

  type SendHapticFeedback = {
    (): void;
    fired?: boolean;
  };

  /* istanbul ignore next -- @preserve */
  const sendHapticFeedback: SendHapticFeedback = useCallback(async () => {
    await Haptics.impact({ style: ImpactStyle.Medium });
    sendHapticFeedback.fired = true;
  }, []);

  const onTouchStart = (e: TouchEvent): void => {
    if (isDragging.current) isDragging.current = false;
    // if at the top of the page cancel
    if (e.type === 'touchstart' && isTreeScrollable(e.target as HTMLElement, DIRECTION.UP)) {
      return;
    }
    // Top is invisible so cancel
    if (childRef.current!.getBoundingClientRect().top < 0) return;

    startY.current = e.touches[0].pageY;
    currentY.current = startY.current;
    isDragging.current = true;
  };

  const onTouchMove = useCallback(
    (e: TouchEvent): void => {
      if (!isDragging.current) return;

      // if scrolling down cancel
      if (currentY.current - startY.current < 0) {
        if (isDragging.current) isDragging.current = false;
        return;
      }

      currentY.current = e.touches[0].pageY;

      const yDistanceMoved = Math.min((currentY.current - startY.current) / resistance, maxPullDownDistance);

      // Send an impact haptic when pull down threshold has been met
      if (currentY.current - startY.current > pullDownThreshold && !sendHapticFeedback.fired) sendHapticFeedback();

      // maxPullDownDistance reached, stop the animation
      if (yDistanceMoved >= maxPullDownDistance || yDistanceMoved < 0) return;

      // stop scroll behaviour on child through when the user is pulling down
      parentRef.current!.style.height = '100%';
      parentRef.current!.style.overflowY = 'hidden';

      pullDownContainerRef.current!.style.top = `${yDistanceMoved - 30}px`;
      pullDownIconContainerRef.current!.style.transform = `rotate(${5 * yDistanceMoved}deg)`;
      pullDownIconRef.current!.style.opacity = `${yDistanceMoved / maxPullDownDistance + 0.2}`;
    },
    [maxPullDownDistance, pullDownThreshold, resistance, sendHapticFeedback]
  );

  const onEnd = useCallback((): void => {
    sendHapticFeedback.fired = false;
    if (currentY.current - startY.current > pullDownThreshold) {
      pullDownIconContainerRef.current!.style.animation = 'loading-animation 0.3s linear infinite';
      setTimeout(() => window.location.reload(), 500);
    } else {
      pullDownContainerRef.current!.style.transition = 'top 0.3s cubic-bezier(0, 0, 0.31, 1)';
      initContainer();
    }
    if (isDragging.current) isDragging.current = false;
  }, [pullDownThreshold, sendHapticFeedback]);

  useEffect(() => {
    /* istanbul ignore next -- @preserve */
    if (!isNativeDevice || !childRef.current) return;
    const childrenEl = childRef.current;
    childrenEl.addEventListener('touchstart', onTouchStart, { passive: true });
    childrenEl.addEventListener('touchmove', onTouchMove, { passive: true });
    childrenEl.addEventListener('touchend', onEnd, { passive: true });
    initContainer();

    return () => {
      childrenEl.removeEventListener('touchstart', onTouchStart);
      childrenEl.removeEventListener('touchmove', onTouchMove);
      childrenEl.removeEventListener('touchend', onEnd);
    };
  }, [isNativeDevice, onEnd, onTouchMove]);

  /* istanbul ignore next -- @preserve */
  if (!isNativeDevice) return children;

  return (
    <Box className="ptr-container" ref={parentRef}>
      <Center position="absolute" top="-30px" w="100%" ref={pullDownContainerRef} zIndex={9999999}>
        <Center
          ref={pullDownIconContainerRef}
          w={7}
          h={7}
          rounded="100%"
          bg="white"
          mx="auto"
          boxShadow=" rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
        >
          <Center ref={pullDownIconRef}>
            <Icon as={BsArrowClockwise} color="gray.800" w={5} h={5} />
          </Center>
        </Center>
      </Center>
      <Box ref={childRef}>{children}</Box>
    </Box>
  );
};

import './App.scss';

import React, { Suspense } from 'react';
import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { CenteredLoader } from 'clipsal-cortex-ui/src/components/CenteredLoader';

import { IS_DEMO_LOGIN, SIDENAV_WIDTH, TOP_BANNER_HEIGHT } from './common/constants';
import { useAmplitude } from './common/hooks/use-amplitude';
import { useAppReady } from './common/hooks/use-app-ready';
import { useBillShare } from './common/hooks/use-bill-share';
import { useDeepLinkSetup } from './common/hooks/use-deep-link-setup';
import { usePushNotificationSetup } from './common/hooks/use-push-notification-setup';
import { useStatusBarStyleConfiguration } from './common/hooks/use-status-bar-style-configuration';
import { useTopBanner } from './common/hooks/use-top-banner';
import { ExperimentalFeaturesModal } from './features/profile/ExperimentalFeaturesModal';
import { DemoLoginBanner } from './features/site/DemoLoginBanner';
import { DeviceAlertBanner } from './features/site/device-alerts/DeviceAlertBanner';
import { selectSite } from './features/site/siteSlice';
import { useMinComponentHeight } from './utils/common/common-utils';

export function App() {
  const site = useSelector(selectSite);
  const hasTopBanner = useTopBanner();
  const minHeight = useMinComponentHeight();
  const isLoaded = useAppReady();
  /* istanbul ignore next -- @preserve */
  useAmplitude();
  useStatusBarStyleConfiguration();
  usePushNotificationSetup();
  useDeepLinkSetup();
  useBillShare();

  return (
    <Box minHeight="100vh" pt={hasTopBanner ? TOP_BANNER_HEIGHT : undefined}>
      <Box
        style={{ backdropFilter: 'blur(6px)' }}
        width={['100vw', '100vw', `calc(100vw - ${SIDENAV_WIDTH})`]}
        zIndex={10}
        position="fixed"
        height={'env(safe-area-inset-top)'}
        top="0px"
      />

      {/* Top banners (conditionally displayed). */}
      {IS_DEMO_LOGIN && <DemoLoginBanner />}
      {!!site.site_id &&
        site.has_valid_subscription &&
        !IS_DEMO_LOGIN &&
        (site.hasDeviceFault || site.commissioning_status === 'PENDING') && <DeviceAlertBanner />}

      {isLoaded ? (
        <Box minHeight={minHeight}>
          <Suspense fallback={<CenteredLoader isFullHeight />}>
            <Outlet />
          </Suspense>
        </Box>
      ) : (
        <Box height="100vh">
          <CenteredLoader />
        </Box>
      )}

      <ExperimentalFeaturesModal />
    </Box>
  );
}

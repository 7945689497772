import { Capacitor } from '@capacitor/core';
import { PushNotifications, Token } from '@capacitor/push-notifications';
import { ChannelType } from 'aws-sdk/clients/pinpoint';

import { put } from '../../common/api/api-helpers';

type Platform = 'web' | 'ios' | 'android';

/* istanbul ignore next -- @preserve */
export const PLATFORM: Platform = Capacitor.getPlatform() as Platform;

/**
 * Sets up the application to receive push notifications.
 */
const onRegistration = async ({ value }: Token) => {
  // Send our token to the API for registration in our own database
  const platformToChannelType: Record<Platform, ChannelType | null> = {
    web: null,
    android: 'GCM',
    ios: 'APNS',
  };

  const channelType = platformToChannelType[PLATFORM];
  if (channelType && value) {
    await put('/v1/notifications/token', {
      token: value,
      channel_type: channelType,
      app_name: 'CORTEX',
    });
  }
};

/* istanbul ignore next -- @preserve */
export async function setupPushNotifications() {
  let permStatus = await PushNotifications.checkPermissions();

  if (['prompt', 'prompt-with-rationale'].includes(permStatus.receive)) {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive === 'granted') {
    // Initialize registration listener functions before beginning the registration process
    PushNotifications.addListener('registration', onRegistration);

    // other events:
    // 'pushNotificationActionPerformed'
    // 'pushNotificationReceived'
    // PushNotifications.getDeliveredNotifications()

    PushNotifications.addListener('registrationError', (error) => {
      console.error(error);
    });

    await PushNotifications.register();
  }
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

interface Profile {
  isExperimentalFeaturesModalOpen: boolean;
}

const initialState: Profile = {
  isExperimentalFeaturesModalOpen: false,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    toggleExperimentalFeaturesModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isExperimentalFeaturesModalOpen: action.payload,
      };
    },
  },
});

export const { toggleExperimentalFeaturesModal } = profileSlice.actions;

export const selectIsExperimentalFeaturesModalOpen = (state: RootState) => {
  return state.profile.isExperimentalFeaturesModalOpen;
};

export const { reducer: profileReducer } = profileSlice;

import React, { useCallback, useMemo, useState } from 'react';
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { Box, Button, Center, Heading, Spinner, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import { useLocation, useNavigate } from 'react-router-dom';

import { OTPInput } from 'clipsal-cortex-ui/src/components/OTPInput';
import { useOnMount } from 'clipsal-cortex-utils/src/hooks/use-on-mount';

import { ReactComponent as MailOtp } from '../../../assets/images/mail_otp.svg';
import { AuthError, NetworkError } from '../../../common/api/api-helpers';
import { ErrorType, OTP_ERROR_TYPE_TO_TOAST_CONFIG } from './otp-verification-helpers';

export function OTPVerification() {
  const navigate = useNavigate();
  const borderColor = useColorModeValue('rgba(0, 0, 0, 0.25)', 'rgba(255, 255, 255, 0.25)');
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const username = urlParams.get('username') || '';
  const otpCode = urlParams.get('code') || '';

  const [{ otp, isLoading, isError, isSuccess }, setState] = useState({
    isLoading: true,
    isError: false,
    isSuccess: false,
    otp: otpCode,
  });
  const toast = useToast({ duration: 3000, status: 'error', isClosable: true });

  const autoSignIn = useCallback(async () => {
    try {
      await Auth.confirmSignUp(username, otpCode);
      toast({
        title: `You have been successfully signed up!`,
        description: `Redirecting to login page...`,
        status: 'success',
      });
      setTimeout(() => {
        navigate('/login', { replace: true });
      }, 1500);
      setState((prevState) => ({ ...prevState, isLoading: false, isError: false, isSuccess: true }));
    } catch (e) {
      const errorType = ((e as AuthError)?.name ?? (e as AuthError)?.response?.name ?? '') as ErrorType;
      const errorKey = errorType in OTP_ERROR_TYPE_TO_TOAST_CONFIG ? errorType : 'Default';
      const { title, description } = OTP_ERROR_TYPE_TO_TOAST_CONFIG[errorKey];
      toast({ title, description });
      setState((prevState) => ({ ...prevState, isLoading: false, isError: true }));
    }
  }, [otpCode, toast, username, navigate]);

  useOnMount(autoSignIn);

  const { buttonName, buttonIcon, colorScheme } = useMemo(() => {
    const config = {
      buttonName: 'Verifying OTP...',
      buttonIcon: <Spinner size="sm" />,
      colorScheme: 'dusk100',
    };

    if (isSuccess) {
      config.buttonName = 'Verified. Redirecting...';
      config.buttonIcon = <CheckCircleIcon />;
      config.colorScheme = 'green';
    } else if (!isLoading && isError) {
      config.buttonName = 'OTP Not Verified';
      config.buttonIcon = <WarningIcon />;
      config.colorScheme = 'red';
    }

    return config;
  }, [isSuccess, isLoading, isError]);

  return (
    <>
      <Center flexDirection={'column'} textAlign={'center'}>
        <Center cursor={'pointer'} my={6} w={'60%'}>
          <MailOtp />
        </Center>
        <Heading fontWeight={700} size="md" mb={4}>
          Verifying your email
        </Heading>

        <Box w="35%" maxW={100} mx="auto" borderTop="1px solid" borderColor={borderColor} />
      </Center>

      <Center data-testid="otp-form">
        <Box minW={320} mx="auto" my={4}>
          <Text fontWeight={'bold'}>Verification code</Text>

          <Box mt={2} maxW={320}>
            <Box>
              <OTPInput
                defaultValue={otp}
                isDisabled
                onChange={(otp) => setState((prevState) => ({ ...prevState, otp, isError: false }))}
                bg="transparent"
                isError={isError}
              />
              <Text color={'red.400'} h={4} mt={1} data-testid="otp-error-message">
                {isError && 'Could not verify OTP. Please try again!'}
              </Text>
              <Button
                data-testid="submit-otp-button"
                isLoading={isLoading}
                loadingText={'Verifying OTP...'}
                w="100%"
                maxW={320}
                rounded={3}
                py={6}
                mt={4}
                isDisabled
                colorScheme={colorScheme}
                leftIcon={buttonIcon}
              >
                {buttonName}
              </Button>
            </Box>
          </Box>
        </Box>
      </Center>

      <Center>
        <Text fontSize={'sm'} textAlign="center">
          Didn’t work?
          <Box
            data-testid="resend-otp-button"
            cursor={'pointer'}
            _hover={{ textDecoration: 'underline', cursor: isLoading ? 'not-allowed' : 'pointer' }}
            onClick={async () => {
              if (isLoading) return;
              try {
                await Auth.resendSignUp(username);
                toast({
                  title: 'OTP has been sent!',
                  status: 'success',
                });
              } catch (e) {
                const errorType = (e as AuthError)?.name ?? (e as AuthError)?.response?.name ?? '';
                const errorMessage = (e as NetworkError)?.message ?? (e as NetworkError)?.response?.data?.detail ?? '';

                const isUserNotInPending =
                  errorType === 'UserLambdaValidationException' &&
                  errorMessage.includes('UserPending matching query does not exist');

                let title = 'Something went wrong while resending OTP!';
                let description = 'Please contact us if this issue persists.';
                let status = 'error' as 'error' | 'info';

                if (isUserNotInPending) {
                  title = 'Verification not required!';
                  description = 'Please try logging in.';
                  status = 'info';
                }

                toast({
                  title,
                  description,
                  status,
                  duration: 5000,
                });

                if (isUserNotInPending) navigate('/login', { replace: true });
              }
            }}
            as={'span'}
            fontWeight="bold"
            color="customBlue.500"
            mx={1}
          >
            Resend
          </Box>
        </Text>
      </Center>
    </>
  );
}

import React from 'react';
import { Badge, Box, Flex, Text } from '@chakra-ui/react';

import { formatDollarsCents } from 'clipsal-cortex-utils/src/formatting/number-formatting';

import { useTariffFormContext } from '../../tariff-form-context';

export function RealTimeRateSummary() {
  const {
    formData: { realTimeRates },
  } = useTariffFormContext();

  return (
    <Box data-testid="real-time-tariff-summary" mx={2} w={['100%', '100%', '80%', '40%']}>
      <Flex data-testid="real-time-tariff-supply-charge" align="center" justify="space-between">
        <Flex align="center">
          <Text fontWeight="bold">Daily Supply Charge (excl. GST)</Text>
        </Flex>
        <Flex>
          <Text fontWeight="bold">{formatDollarsCents(realTimeRates?.seasons[0].dailySupplyCharge ?? 0)}</Text>
          <Text ml={1} fontWeight="bold">
            / day
          </Text>
        </Flex>
      </Flex>
      <Flex data-testid="real-time-tariff-subscription-fee" mt={2} align="center" justify="space-between">
        <Flex align="center">
          <Text fontWeight="bold">Subscription Fee</Text>
        </Flex>
        <Flex>
          <Text fontWeight="bold">{formatDollarsCents(realTimeRates?.seasons[0].monthlyFee ?? 0)}</Text>
          <Text ml={1} fontWeight="bold">
            / month
          </Text>
        </Flex>
      </Flex>
      <Flex data-testid="real-time-tariff-import-rate" mt={2} align="center" justify="space-between">
        <Flex align="center">
          <Text fontWeight="bold">Import Rate</Text>
        </Flex>
        <Flex>
          <Badge colorScheme="blue">REAL-TIME</Badge>
        </Flex>
      </Flex>
      {realTimeRates?.seasons[0].hasSolarFeedIn && (
        <Flex data-testid="real-time-tariff-solar-feed-in-rate" mt={2} align="center" justify="space-between">
          <Flex align="center">
            <Text fontWeight="bold">Solar Feed-in Rate</Text>
          </Flex>
          <Flex>
            <Badge colorScheme="blue">REAL-TIME</Badge>
          </Flex>
        </Flex>
      )}
      {realTimeRates?.seasons[0].hasControlledLoad && (
        <Flex data-testid="real-time-tariff-controlled-load-rate" mt={2} align="center" justify="space-between">
          <Flex align="center">
            <Text fontWeight="bold">Controlled Load Rate</Text>
          </Flex>
          <Flex>
            <Text fontWeight="bold">DYNAMIC</Text>
          </Flex>
        </Flex>
      )}
    </Box>
  );
}

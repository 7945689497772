import * as amplitude from '@amplitude/analytics-browser';
import { Preferences as Storage } from '@capacitor/preferences';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

import { UserData } from 'clipsal-cortex-types/src/api/api-user';
import { CachedSession, getCachedSession } from 'clipsal-cortex-utils/src/auth/cached-session';

import { RootState } from '../../app/store';
import { get, getCurrentSession } from '../../common/api/api-helpers';
import { IS_DEMO_LOGIN, IS_PRODUCTION_BUILD, IS_RUNNING_CYPRESS_TESTS } from '../../common/constants';
import { SitesResponse } from '../sites/sitesSlice';
import { setupAmplitude, setupLogRocket } from './user-helpers';

export interface User {
  isLoggedIn: boolean;
  hasFetchedUserDetails: boolean;
  token: string;
  role: string;
  accessToken: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  cognitoUser: CognitoUser | null;
  sendEmailAction: string;
  siteIDs: number[];
  userID: number;
  tenantID: number;
}

export const cacheSiteIDs = async (sites: SitesResponse) => {
  const siteIDs = sites.data.map((site) => site.site_id);
  await Storage.set({ key: 'cachedSiteIDs', value: JSON.stringify(siteIDs) });
  return siteIDs;
};

export async function getCachedSiteIDs(): Promise<number[]> {
  const { value } = await Storage.get({ key: 'cachedSiteIDs' });
  return value ? JSON.parse(value) : [];
}

export const checkUserLoggedIn = createAsyncThunk('user/checkUserLoggedIn', async () => {
  let session: CachedSession;
  let isLoggedIn = false;
  let idToken = '';
  let accessToken = '';
  let cognitoUser: CognitoUser | null = null;

  if (IS_DEMO_LOGIN) {
    return {
      ...initialState,
      isLoggedIn: true,
    };
  }

  try {
    session = await getCurrentSession();
    cognitoUser = await Auth.currentUserPoolUser();
    isLoggedIn = true;
    idToken = session.token;
    accessToken = session.accessToken;
  } catch (error) {
    console.error(error);
  }

  // Return the successfully authorized user's token.
  return {
    ...initialState,
    cognitoUser,
    isLoggedIn,
    accessToken,
    token: idToken,
  };
});

/**
 * Fetches user details from the `User` end-point.
 */
export const fetchUserDetails = createAsyncThunk<
  User,
  { isProduction: boolean; isNotDevelopment: boolean },
  { state: RootState }
>('user/fetchUserDetails', async ({ isProduction, isNotDevelopment }, { getState }) => {
  let userData: UserData | null = null;
  let sites: SitesResponse | null = null;

  try {
    [userData, sites] = await Promise.all([
      get<UserData>('/v1/user'),
      get<SitesResponse>('/v1/sites?limit=2&offset=0'),
    ]);
  } catch (error) {
    console.error(error);
    // This could fail for multiple reasons
    // We check for cached values to ensure this is related to api
    // and not due to offline network
    const session = await getCachedSession();
    const siteIDs = await getCachedSiteIDs();
    const isLoggedIn = session ? session.expiration > Date.now() / 1000 : false;
    return { ...getState().user, isLoggedIn, siteIDs };
  }

  // Only send events to sentry in staging and production environments
  if (isNotDevelopment && userData?.user_id) {
    Sentry.setUser({ id: userData.user_id.toString() });
  }

  // Setup LogRocket _only_ for authorized users who are logged in.
  if (userData?.user_id && userData.role !== 'SUPER_ADMIN' && isNotDevelopment && !IS_RUNNING_CYPRESS_TESTS) {
    await setupAmplitude(userData);

    if (isProduction) setupLogRocket(userData);
  }

  // Catch SiteIDs to figure out which route should users be routed when offline
  const siteIDs = await cacheSiteIDs(sites);

  return {
    ...getState().user,
    hasFetchedUserDetails: !!userData,
    email: userData?.email,
    firstName: userData?.first_name,
    lastName: userData?.last_name,
    phone: userData?.phone,
    sendBillsAction: userData?.send_bills_action,
    siteIDs,
    userID: userData?.user_id,
    role: userData?.role,
    tenantID: userData?.tenant_id,
  };
});

export const initialState: User = {
  isLoggedIn: false,
  hasFetchedUserDetails: false,
  cognitoUser: null,
  token: '',
  accessToken: '',
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  phone: '',
  sendEmailAction: '',
  siteIDs: [],
  userID: 0,
  tenantID: 0,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logIn: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isLoggedIn: true,
        token: action.payload,
      };
    },
    logOut: () => {
      if (IS_PRODUCTION_BUILD) amplitude.reset();
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkUserLoggedIn.fulfilled, (state, action) => {
        return {
          ...state,
          isLoggedIn: action.payload.isLoggedIn,
          token: action.payload.token,
          cognitoUser: action.payload.cognitoUser,
          accessToken: action.payload.accessToken,
        };
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        return action.payload ? action.payload : state;
      })
      .addCase(fetchUserDetails.rejected, () => {
        return initialState;
      });
  },
});

export const { logIn, logOut } = userSlice.actions;

export const selectUser = (state: RootState): User => {
  return state.user;
};

export const { reducer: userReducer } = userSlice;

import React from 'react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { IS_NATIVE } from 'se-home/src/common/constants';

import { AlertDialogModal } from 'clipsal-cortex-ui/src/components/AlertDialogModal';
import { CenteredLoader } from 'clipsal-cortex-ui/src/components/CenteredLoader';
import { TESLA_MANUFACTURER_ID } from 'clipsal-cortex-utils/src/constants/common-constants';

import { selectSite } from '../../../site/siteSlice';
import { ProfilePageBase } from '../../ProfilePageBase';
import { useAvailableVehicles, useDeleteConnectionMutation, useGetConnectionStatusQuery } from './teslaApi';

export function ManageTeslaIntegration() {
  const { site_id: siteId, devices } = useSelector(selectSite);
  const toast = useToast({ isClosable: true });
  const { data: connectionStatus, isLoading, isError } = useGetConnectionStatusQuery(siteId);
  const isConnectionError = !isError && !isLoading && connectionStatus?.status !== 'success';
  const [deleteTeslaAccountFromSite, { isLoading: isDeletingTeslaIntegration }] = useDeleteConnectionMutation();
  // Note: Skip loading vehicles if the user already has a Tesla vehicle in our system.
  // @TODO: Support for multiple Tesla EVs, currently the key pairing process isn't clear for multiple vehicles.
  const hasTeslaEVCharger = devices.some(
    ({ manufacturer_id: manufacturerId, device_type: deviceType }) =>
      deviceType == 'EV_CAR' && manufacturerId === TESLA_MANUFACTURER_ID
  );
  const {
    availableVINs,
    isLoading: isAvailableVehiclesLoading,
    isError: isAvailableVehiclesError,
  } = useAvailableVehicles(hasTeslaEVCharger);
  const hasTeslaVehicles = !isLoading && !isError && availableVINs.length;
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const shouldDisplayTroubleshootingAlert = search.get('displayTroubleshooting') == 'true';

  const {
    isOpen: isDeleteIntegrationAlertDialogOpen,
    onOpen: onOpenDeleteIntegrationAlertDialog,
    onClose: onCloseDeleteIntegrationAlertDialog,
  } = useDisclosure();
  const {
    isOpen: isPairingAlertModalOpen,
    onOpen: onOpenPairingAlertDialog,
    onClose: onClosePairingAlertDialog,
  } = useDisclosure();
  const backURL = '../integrations';

  async function handleDeleteTeslaIntegration() {
    try {
      await deleteTeslaAccountFromSite(siteId).unwrap();
      toast({
        title: 'Successfully removed your Tesla account from Cortex.',
        description: 'You can link it again at any time.',
        status: 'success',
      });
      navigate(backURL);
    } catch (e) {
      console.error(e);
      toast({
        title: 'Something went wrong logging out of your Tesla account.',
        description: 'Please contact support.',
        status: 'error',
      });
    }
  }

  async function handleConfirmBeginPairingTeslaEV() {
    window.location.replace('https://tesla.com/_ak/app-api.clipsalcortex.com');
    localStorage.setItem('hasAttemptedTeslaPairing', 'true');
  }

  if (isLoading) return <CenteredLoader />;

  if (isConnectionError) {
    return (
      <Alert mt={5} status="error" variant="left-accent" data-testid="error-alert">
        <AlertIcon />
        <Box>
          <AlertTitle>Something went wrong.</AlertTitle>
          <AlertDescription>
            <Text>You do not have a Tesla integration to manage.</Text>
            <Link color="customBlue.500" as={RouterLink} to={backURL}>
              Click here to go back
            </Link>
          </AlertDescription>
        </Box>
      </Alert>
    );
  }

  return (
    <>
      <ProfilePageBase title={'Manage Tesla Integration'} backURL={backURL}>
        <Flex align={'center'} flexDirection={'column'} py={5} data-testid="manage-tesla-integration-container">
          <Heading textAlign={'center'} size={'md'}>
            Your Tesla account is connected to Clipsal Cortex
          </Heading>
          {shouldDisplayTroubleshootingAlert && (
            <Alert data-testid="tesla-pairing-troubleshooting-alert" mt={4} py={3} rounded={10} status="info">
              <AlertIcon />
              <Box>
                <AlertTitle>Having trouble pairing your Tesla vehicle?</AlertTitle>
                <AlertDescription>
                  <Link
                    data-testid="tesla-pairing-troubleshooting-link"
                    color="customBlue.500"
                    as={RouterLink}
                    to="vehicle-pairing-troubleshooting"
                  >
                    Tap here
                  </Link>{' '}
                  for troubleshooting tips.
                </AlertDescription>
              </Box>
            </Alert>
          )}
          <CheckCircleIcon color="green.500" w={['50%', '50%', '25%']} h={['50%', '50%', '25%']} my={10} />
          {!isAvailableVehiclesLoading && !isAvailableVehiclesError && hasTeslaVehicles ? (
            <PairTeslaVehicle onOpenPairingAlertDialog={onOpenPairingAlertDialog} />
          ) : null}

          <Button
            w={['75%', '75%', '75%']}
            mb={2}
            rounded={20}
            colorScheme="red"
            isLoading={isDeletingTeslaIntegration}
            onClick={onOpenDeleteIntegrationAlertDialog}
            data-testid="delete-tesla-integration-button"
          >
            Delete Tesla Integration
          </Button>

          <Button
            w={['75%', '75%', '75%']}
            rounded={20}
            mb={2}
            colorScheme="dusk100"
            onClick={() => navigate(-1)}
            data-testid="inline-back-btn"
            variant="outline"
          >
            Back to Profile
          </Button>
        </Flex>
      </ProfilePageBase>

      <AlertDialogModal
        header="Are you sure you want to delete your Tesla integration?"
        subHeader=" "
        isOpen={isDeleteIntegrationAlertDialogOpen}
        onClose={onCloseDeleteIntegrationAlertDialog}
        confirmButtonTextColor={'customRed.500'}
        isConfirming={isDeletingTeslaIntegration}
        onConfirm={handleDeleteTeslaIntegration}
      />

      <AlertDialogModal
        header="Pairing your Tesla Electric Vehicle"
        subHeader={
          'You will be redirected to the official Tesla app. ' +
          'You must have the Tesla app installed to proceed. After this is complete, return here' +
          ' to continue the setup.'
        }
        isOpen={isPairingAlertModalOpen}
        onClose={onClosePairingAlertDialog}
        onConfirm={handleConfirmBeginPairingTeslaEV}
      />
    </>
  );
}

function PairTeslaVehicle({ onOpenPairingAlertDialog }: { onOpenPairingAlertDialog: () => void }) {
  if (!IS_NATIVE) {
    return (
      <Alert data-testid="non-native-tesla-ev-detection-alert" my={3} py={3} rounded={10} status="info">
        <AlertIcon />
        <Box>
          <AlertTitle>Tesla Electric Vehicle detected</AlertTitle>
          <AlertDescription>
            Download the Clipsal Cortex app to pair your EV and start controlling it from the app
          </AlertDescription>
        </Box>
      </Alert>
    );
  }

  return (
    <Button
      w={['75%', '75%', '75%']}
      mb={2}
      rounded={20}
      colorScheme="dusk100"
      onClick={onOpenPairingAlertDialog}
      data-testid="connect-tesla-ev-button"
    >
      Pair Tesla Electric Vehicle
    </Button>
  );
}

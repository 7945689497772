import React, { useState } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { FieldErrors, UseFormHandleSubmit, UseFormRegister } from 'react-hook-form';

import networkInfoImg from '../../../assets/images/network_info.png';
import { MOBILE_COMPONENT_HEIGHT } from '../../../common/constants';
import { useViewportType } from '../../../common/hooks/use-viewport-type';
import { WifiDetailsFormData } from './wifi-config-helpers';

type WifiDetailsFormProps = {
  isLoading: boolean;
  onSubmit: (data: WifiDetailsFormData) => void;
  containerProps?: BoxProps;
  buttonProps?: ButtonProps;
  buttonName?: string;
  register: UseFormRegister<WifiDetailsFormData>;
  handleSubmit: UseFormHandleSubmit<WifiDetailsFormData>;
  errors: FieldErrors<WifiDetailsFormData>;
};

export const WifiDetailsForm = ({
  isLoading,
  onSubmit,
  containerProps,
  buttonProps,
  buttonName = 'Save',
  register,
  handleSubmit,
  errors,
}: WifiDetailsFormProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isDesktopViewport } = useViewportType();

  const headerContents = (
    <Box>
      <HStack justify={'space-between'} p={4} py={2}>
        <Button variant="ghost" colorScheme="primaryBranding" fontWeight={400} onClick={onClose}>
          Back
        </Button>
        <Heading size="sm">More information</Heading>
        <Box w={68} />
      </HStack>
      <Divider borderColor="gray.500" />
    </Box>
  );

  return (
    <VStack px={2} h="100%" {...containerProps}>
      <FormControl isInvalid={!!errors?.ssid} mb={6} data-testid="ssid">
        <FormLabel>SSID</FormLabel>
        <Input {...register('ssid')} type="text" placeholder={'Enter SSID'} />
        <FormErrorMessage>{errors?.ssid?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors?.password} data-testid="password">
        <FormLabel>Password</FormLabel>
        <InputGroup>
          <Input
            {...register('password')}
            type={isPasswordVisible ? 'text' : 'password'}
            placeholder={'Enter Password'}
          />
          <InputRightElement
            onClick={() => setIsPasswordVisible((prevState) => !prevState)}
            data-testid="view-password-btn"
          >
            {isPasswordVisible ? <ViewOffIcon w={5} h={5} /> : <ViewIcon w={5} h={5} />}
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
      </FormControl>

      <Link w="100%" my={6} color="customBlue.500" onClick={onOpen} data-testid="wifi-connection-info-button">
        How do I know if Wi-Fi network is connected?
      </Link>

      {isDesktopViewport ? (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size={'xl'}>
          <ModalOverlay />
          <ModalContent data-testid="wifi-connection-modal">
            {headerContents}
            <ModalBody p={0}>
              <Image src={networkInfoImg} alt="network information" w="100%" borderBottomRadius={10} />
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        <Drawer isOpen={isOpen} onClose={onClose} size={'xl'} placement="bottom">
          <DrawerOverlay />
          <DrawerContent
            minH={`calc(${MOBILE_COMPONENT_HEIGHT} - 10vh)`}
            borderTopRadius={10}
            data-testid="wifi-connection-drawer"
          >
            {headerContents}
            <DrawerBody p={0}>
              <Image src={networkInfoImg} alt="network information" w="100%" borderBottomRadius={10} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}

      <Box mt="auto">
        <Button
          data-testid="save-wifi-config-btn"
          minW={260}
          borderRadius={50}
          colorScheme="dusk100"
          fontWeight={400}
          mt={6}
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
          {...buttonProps}
        >
          {buttonName}
        </Button>
      </Box>
    </VStack>
  );
};

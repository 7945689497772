import React, { useCallback, useMemo } from 'react';
import { Button, Center, useDisclosure, useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AlertDialogModal } from 'clipsal-cortex-ui/src/components/AlertDialogModal';

import { useGetCombinedSiteDeviceSchedules, usePutSiteDeviceSchedulesMutation } from '../../scheduler/schedulerApi';
import { useGetSiteSolarSpongeConfigQuery } from '../../site/control-profile/siteSolarSpongeConfigApi';
import { selectSite } from '../../site/siteSlice';
import { SmartSaveStatus } from './smart-save-helpers';
import { selectSmartSaveDevices, selectSmartSaveStatus } from './smartSaveSlice';
import { useSaveSmartSaveConfig } from './use-save-smartsave-config';
import { useSmartSaveStatus } from './use-smartsave-status';

export const SaveOptimisationsButton = () => {
  const navigate = useNavigate();
  const smartSaveDevices = useSelector(selectSmartSaveDevices);

  const { schedules: combinedSiteDeviceSchedules, isLoading: isLoadingCombinedSiteDeviceSchedules } =
    useGetCombinedSiteDeviceSchedules(smartSaveDevices.map((d) => d.site_device_id));

  const hasActiveSchedules = useMemo(
    () =>
      Object.values(combinedSiteDeviceSchedules)
        .flat()
        .some((schedule) => schedule.active),
    [combinedSiteDeviceSchedules]
  );

  const smartSaveStatus = useSelector(selectSmartSaveStatus);
  const { smartSaveStatus: smartSaveStatusAPI } = useSmartSaveStatus();
  const { site_id: siteId } = useSelector(selectSite);
  const { data: siteSmartSaveConfig } = useGetSiteSolarSpongeConfigQuery(siteId);
  const { saveSmartSaveConfig, isUpdating } = useSaveSmartSaveConfig();
  const [updateSiteDeviceSchedules, { isLoading: isUpdateScheduleLoading }] = usePutSiteDeviceSchedulesMutation();
  const {
    isOpen: isTurnOffSmartSaveDialogOpen,
    onClose: onCloseTurnOffSmartSaveDialog,
    onOpen: onOpenTurnOffSmartSaveDialog,
  } = useDisclosure();
  const {
    isOpen: isTurnOnSmartSaveDialogOpen,
    onClose: onCloseTurnOnSmartSaveDialog,
    onOpen: onOpenTurnOnSmartSaveDialog,
  } = useDisclosure();
  const toast = useToast();

  const isDisabled = useMemo(() => {
    const smartSaveAPIActiveBitMap = siteSmartSaveConfig?.solar_sponge_devices?.map((d) => d.active) ?? [];
    const smartSaveUIActiveBitMap = smartSaveDevices.map((d) => d.active);

    // Ensures that if new devices exist, the save button is enabled
    if (smartSaveAPIActiveBitMap.length !== smartSaveUIActiveBitMap.length) return false;

    // Ensures that if the status has changed, the save button is enabled
    if (smartSaveStatus !== smartSaveStatusAPI) return false;

    return JSON.stringify(smartSaveAPIActiveBitMap) === JSON.stringify(smartSaveUIActiveBitMap);
  }, [siteSmartSaveConfig?.solar_sponge_devices, smartSaveDevices, smartSaveStatus, smartSaveStatusAPI]);

  /// It is required to deactivate active schedules for devices which are enabled for SmartSave
  const deactivateSiteDeviceSchedules = useCallback(async () => {
    // only deactivate schedules for devices that are active
    const activeSmartSaveDeviceIds = smartSaveDevices
      .filter((device) => device.active)
      .map((device) => device.site_device_id);

    // create promises for each siteDeviceId
    const siteDeviceSchedulePromises = Object.entries(combinedSiteDeviceSchedules)
      .filter(([siteDeviceId]) => activeSmartSaveDeviceIds.includes(parseInt(siteDeviceId)))
      .map(([siteDeviceId, schedules]) =>
        updateSiteDeviceSchedules({
          siteDeviceId: parseInt(siteDeviceId),
          schedules: schedules.map((schedule) => ({ ...schedule, active: false })),
          deleteMissingObjects: true,
        }).unwrap()
      );

    try {
      await Promise.all(siteDeviceSchedulePromises);
    } catch {
      toast.closeAll();
      toast({
        title: 'Failed to update schedules',
        description: 'If this persists, please contact support',
        status: 'error',
      });
      return;
    }
  }, [combinedSiteDeviceSchedules, smartSaveDevices, toast, updateSiteDeviceSchedules]);

  return (
    <>
      <Center mt="auto" mb={6}>
        <Button
          data-testid="save-optimisations-button"
          mt={6}
          alignSelf={['center', 'center', 'flex-start']}
          mb={2}
          rounded={20}
          colorScheme="dusk100"
          w={'70vw'}
          maxW={500}
          isDisabled={isDisabled || isLoadingCombinedSiteDeviceSchedules}
          isLoading={isUpdating || isUpdateScheduleLoading}
          onClick={async () => {
            // If smart save was previously off and now it is on, and there are actives manual schedules, inform user that they will be overridden
            if (
              smartSaveStatusAPI === SmartSaveStatus.OFF &&
              smartSaveStatus === SmartSaveStatus.ON &&
              hasActiveSchedules
            ) {
              return onOpenTurnOnSmartSaveDialog();
            }

            // If smart save was previously on and now it is off, inform user that all optimisations will be turned off
            if (smartSaveStatusAPI === SmartSaveStatus.ON && smartSaveStatus === SmartSaveStatus.OFF) {
              return onOpenTurnOffSmartSaveDialog();
            }

            await saveSmartSaveConfig();
          }}
        >
          Save
        </Button>
      </Center>

      <AlertDialogModal
        header={'Are you sure?'}
        subHeader={
          'Cortex will no longer optimise any of your devices. If you want to change settings for a specific device, go to the Live page.'
        }
        dialogContentTestId="turn-off-smart-save-dialog"
        isOpen={isTurnOffSmartSaveDialogOpen}
        onClose={onCloseTurnOffSmartSaveDialog}
        confirmButtonTextColor={'customRed.500'}
        onCancel={() => {
          navigate(`/site/${siteId}/live`);
        }}
        onConfirm={() => {
          onCloseTurnOffSmartSaveDialog();
          saveSmartSaveConfig();
        }}
        confirmButtonName="Proceed and turn off optimiser"
        cancelButtonName="Go to Live Page"
      />

      <AlertDialogModal
        header={'Are you sure?'}
        subHeader={'Optimiser will override any existing manual schedules.'}
        dialogContentTestId="turn-on-smart-save-dialog"
        isOpen={isTurnOnSmartSaveDialogOpen}
        onClose={onCloseTurnOnSmartSaveDialog}
        confirmButtonTextColor={'customRed.500'}
        onConfirm={async () => {
          onCloseTurnOnSmartSaveDialog();
          if (hasActiveSchedules) await deactivateSiteDeviceSchedules();
          await saveSmartSaveConfig();
        }}
        onCancel={() => {
          onCloseTurnOnSmartSaveDialog();
        }}
        confirmButtonName="Proceed"
        cancelButtonName="Cancel"
      />
    </>
  );
};

import React from 'react';
import { Box, HStack, useColorModeValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { selectSite } from '../../site/siteSlice';
import { ProfilePageBase } from '../ProfilePageBase';
import { AmberIntegration, SensiboIntegration, TeslaIntegration } from './ThirdPartyIntegrations';

export function Integrations() {
  const backgroundColor = useColorModeValue('white', 'gray.900');
  const borderColor = useColorModeValue('gray.300', 'black');
  const hoverColor = useColorModeValue('whiteAlpha.700', 'black');
  const {
    integrations: { amber_b2b: hasAmberControlIntegration },
  } = useSelector(selectSite);

  return (
    <ProfilePageBase title={'Integrations'} backURL={`..`}>
      <Box background={backgroundColor}>
        <HStack borderBottom="1px solid" borderColor={borderColor} px={3} minH={100} pt={4} _hover={{ bg: hoverColor }}>
          <TeslaIntegration />
        </HStack>
        <HStack borderBottom="1px solid" borderColor={borderColor} px={3} minH={100} _hover={{ bg: hoverColor }}>
          <SensiboIntegration />
        </HStack>
        {/* NOTE: Users with a control integration manage this through the Amber app.
                  It appears in Cortex under "SmartSave" when enabled. */}
        {!hasAmberControlIntegration && (
          <HStack px={3} minH={100} _hover={{ bg: hoverColor }}>
            <AmberIntegration />
          </HStack>
        )}
      </Box>
    </ProfilePageBase>
  );
}

import { defaultTheme as theme } from '../../../../../styles/theme';

export const TOU_RATE_TYPE_TO_COLOR = {
  'OFF-PEAK': theme.colors.offPeakGreen[500],
  SHOULDER: theme.colors.ellipse[500],
  PEAK: theme.colors.peakRateRed[500],
};
export const TOU_RATE_TYPE_TO_VALUE = {
  'OFF-PEAK': 2,
  SHOULDER: 3,
  PEAK: 4,
};

export const MIDNIGHT_TIME = {
  minutes: 0,
  hours: 0,
  amOrPm: 'AM' as const,
};

export const CHART_HEIGHT_PX = 100;

export const MS_IN_ONE_HOUR = 3_600_000;

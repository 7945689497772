import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Center, Flex, IconButton, Spinner, Tooltip } from '@chakra-ui/react';
import { endOfDay, startOfDay } from 'date-fns';
import { useSelector } from 'react-redux';

import { TOP_NAV_HEIGHT } from 'clipsal-cortex-ui/src/components/MobileTopNav';

import { CustomDatePicker } from '../../common/components/date-picker/CustomDatePicker';
import { TopNav } from '../../common/components/TopNav';
import { SIDENAV_WIDTH, TOP_BANNER_HEIGHT } from '../../common/constants';
import { useTopBanner } from '../../common/hooks/use-top-banner';
import { useViewportType } from '../../common/hooks/use-viewport-type';
import { selectSite } from '../site/siteSlice';

type ActivityLogTopNavProps = {
  isLoading: boolean;
  selectedDate: Date;
  onSelectDate: (date: Date) => void;
};

export const ActivityLogTopNav = ({ isLoading, selectedDate, onSelectDate }: ActivityLogTopNavProps) => {
  const { isDesktopViewport } = useViewportType();
  const { site_id: siteId, monitoring_start: monitoringStartDate } = useSelector(selectSite);
  const hasTopBanner = useTopBanner();

  return (
    <>
      <TopNav backURL={`/site/${siteId}/dashboard`} title="Activity" shouldDisplayTitleOnDesktop />
      <Center
        w="100%"
        zIndex={10}
        py={5}
        data-testid="activity-date-picker-container"
        position={isDesktopViewport ? 'absolute' : 'fixed'}
        mt={`${TOP_NAV_HEIGHT}px`}
        top={`calc(${hasTopBanner ? TOP_BANNER_HEIGHT : '0px'} + env(safe-area-inset-top))`}
        left={isDesktopViewport ? SIDENAV_WIDTH : 0}
        background="backgroundLightMode.500"
        _dark={{ background: 'customGray.500' }}
        width={isDesktopViewport ? `calc(100% - ${SIDENAV_WIDTH})` : '100%'}
      >
        <Flex>
          <Tooltip
            isDisabled={isLoading || selectedDate <= endOfDay(new Date(monitoringStartDate))}
            openDelay={500}
            hasArrow
            label="See earlier dates"
            aria-label={'See earlier dates'}
          >
            <IconButton
              size={'sm'}
              data-testid="prev-date"
              colorScheme={'dusk100'}
              rounded={50}
              variant={'solid'}
              mx={2}
              onClick={() => {
                onSelectDate(new Date(selectedDate.setDate(selectedDate.getDate() - 1)));
              }}
              isDisabled={isLoading || selectedDate <= endOfDay(new Date(monitoringStartDate))}
              aria-label="Move left"
              icon={
                !isLoading ? (
                  <ChevronLeftIcon h={8} w={8} />
                ) : (
                  <Spinner label={'Loading'} color={'clipsalGreen.500'} size={'xs'} />
                )
              }
            />
          </Tooltip>

          <CustomDatePicker
            minDate={new Date(monitoringStartDate)}
            maxDate={new Date()}
            withPortal
            inputProps={{ 'data-testid': 'activity-date-picker' }}
            iconPlacement="right"
            dateFormat="dd/MM/yyyy"
            onChange={(e) => e && onSelectDate(e)}
            selected={selectedDate}
            disabled={isLoading}
          />

          <Tooltip
            isDisabled={selectedDate >= startOfDay(new Date())}
            openDelay={500}
            hasArrow
            label="See later dates"
            aria-label={'See later dates'}
          >
            <IconButton
              size={'sm'}
              data-testid="next-date"
              colorScheme={'dusk100'}
              rounded={50}
              variant={'solid'}
              mx={2}
              onClick={() => {
                onSelectDate(new Date(selectedDate.setDate(selectedDate.getDate() + 1)));
              }}
              isDisabled={isLoading || selectedDate >= startOfDay(new Date())}
              aria-label="Move right"
              icon={
                !isLoading ? (
                  <ChevronRightIcon h={8} w={8} />
                ) : (
                  <Spinner label={'loading'} color={'clipsalGreen.500'} size={'xs'} />
                )
              }
            />
          </Tooltip>
        </Flex>
      </Center>
    </>
  );
};

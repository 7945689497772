import { createContext, Dispatch, SetStateAction, useContext } from 'react';

import { BasicDetailsFormData } from './basic-details/types';
import { FlatTariffFormData } from './rates/flat/types';
import { RealTimeTariffFormData } from './rates/real-time/types';
import { TieredTariffFormData } from './rates/tiered/types';
import { TOUTariffFormData } from './rates/time-of-use/types';
import { ControlledLoadFormData } from './review/additional-rates-and-discounts/controlled-loads/types';
import { DemandChargeFormData } from './review/additional-rates-and-discounts/demand-charges/types';
import { DiscountsFormData } from './review/additional-rates-and-discounts/discounts/types';
import { SolarFeedInFormData } from './review/additional-rates-and-discounts/solar-feed-in/types';

export type TariffFormDataState = {
  basicDetails: BasicDetailsFormData | null;
  touRates: TOUTariffFormData | null;
  flatRates: FlatTariffFormData | null;
  tieredRates: TieredTariffFormData | null;
  realTimeRates: RealTimeTariffFormData | null;
  additionalRatesAndDiscounts: {
    controlledLoads: ControlledLoadFormData | null;
    solarFeedIn: SolarFeedInFormData | null;
    discounts: DiscountsFormData | null;
    demandCharges: DemandChargeFormData | null;
  };
};

export const EMPTY_TARIFF_FORM_DATA = {
  basicDetails: null,
  touRates: null,
  flatRates: null,
  tieredRates: null,
  realTimeRates: null,
  additionalRatesAndDiscounts: {
    controlledLoads: null,
    solarFeedIn: null,
    discounts: null,
    demandCharges: null,
  },
};

type TariffFormContextType = {
  formData: TariffFormDataState;
  onUpdateFormData: Dispatch<SetStateAction<TariffFormDataState>>;
};

export const TariffFormContext = createContext<TariffFormContextType>({
  formData: EMPTY_TARIFF_FORM_DATA,
  onUpdateFormData: () => null,
});

export function useTariffFormContext() {
  return useContext(TariffFormContext);
}

import React, { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';

interface NetworkStatusContextProps {
  isOnline: boolean;
}

const NetworkStatusContext = createContext<NetworkStatusContextProps>({ isOnline: true });

// eslint-disable-next-line react-refresh/only-export-components
export const useNetworkStatus: () => NetworkStatusContextProps = () => useContext(NetworkStatusContext);

export const NetworkStatusProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    /* istanbul ignore next -- @preserve */
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return <NetworkStatusContext.Provider value={{ isOnline }}>{children}</NetworkStatusContext.Provider>;
};

import React, { useMemo, useState } from 'react';
import { AddIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Flex, FlexProps, Text, useColorModeValue } from '@chakra-ui/react';

import { InfoPopover } from 'clipsal-cortex-ui/src/components/InfoPopover';

import { useTariffFormContext } from '../../tariff-form-context';
import { ControlledLoadFormDialog } from './controlled-loads/ControlledLoadFormDialog';
import { DiscountsFormDialog } from './discounts/DiscountsFormDialog';
import { SolarFeedInFormDialog } from './solar-feed-in/SolarFeedInFormDialog';
import { AdditionalRatesAndDiscountsType } from './types';

type Props = {
  invalidRateTypes: AdditionalRatesAndDiscountsType[];
  onUpdateInvalidRateTypes: (newInvalidRateTypes: AdditionalRatesAndDiscountsType[]) => void;
};

export function AdditionalRatesAndDiscounts({ invalidRateTypes, onUpdateInvalidRateTypes }: Props) {
  const {
    formData: {
      additionalRatesAndDiscounts: { solarFeedIn },
    },
  } = useTariffFormContext();
  const [currentlyOpenDialog, setCurrentlyOpenDialog] = useState<AdditionalRatesAndDiscountsType | null>(null);

  function onCloseDialog() {
    setCurrentlyOpenDialog(null);
  }

  const solarButtonProps = invalidRateTypes.includes('SOLAR_FEED_IN')
    ? {
        borderColor: 'red.500',
        borderWidth: '2px',
      }
    : {};

  return (
    <Box mt={5}>
      <AdditionalRateButton
        data-testid="add-controlled-load-summary-btn"
        onClick={() => {
          setCurrentlyOpenDialog('CONTROLLED_LOAD');
        }}
        type="CONTROLLED_LOAD"
      />
      <ControlledLoadFormDialog isOpen={currentlyOpenDialog === 'CONTROLLED_LOAD'} onClose={onCloseDialog} />

      <AdditionalRateButton
        data-testid="add-solar-feed-in-summary-btn"
        onClick={() => {
          setCurrentlyOpenDialog('SOLAR_FEED_IN');
        }}
        type="SOLAR_FEED_IN"
        {...solarButtonProps}
      />
      <SolarFeedInFormDialog
        isOpen={currentlyOpenDialog === 'SOLAR_FEED_IN'}
        onClose={() => {
          onCloseDialog();
          // If the user has now added a controlled load, clear the invalid rate type
          if (solarFeedIn?.rate) {
            onUpdateInvalidRateTypes(invalidRateTypes.filter((type) => type !== 'SOLAR_FEED_IN'));
          }
        }}
      />

      <AdditionalRateButton
        data-testid="add-discounts-summary-btn"
        onClick={() => {
          setCurrentlyOpenDialog('DISCOUNTS');
        }}
        type="DISCOUNTS"
      />
      <DiscountsFormDialog isOpen={currentlyOpenDialog === 'DISCOUNTS'} onClose={onCloseDialog} />

      {/* @TODO: Re-add after MVP feature, needs further consideration */}
      {/*<AdditionalRateButton*/}
      {/*  onClick={() => {*/}
      {/*    setCurrentlyOpenDialog('DEMAND_CHARGES');*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <AddIcon w={5} h={5} mr={2} />*/}
      {/*  <Text fontWeight="bold">Demand Charges</Text>*/}
      {/*  <InfoPopover>This is the demand chages popover </InfoPopover>*/}
      {/*</AdditionalRateButton>*/}
      {/*<DemandChargesFormDialog isOpen={currentlyOpenDialog === 'DEMAND_CHARGES'} onClose={onCloseDialog} />*/}
    </Box>
  );
}

const ADDITIONAL_RATE_TYPE_TO_CONFIG: Record<
  AdditionalRatesAndDiscountsType,
  { text: string; infoPopoverText: string }
> = {
  CONTROLLED_LOAD: {
    text: 'Controlled load rates',
    infoPopoverText:
      'These are separate tariffs that might be connected to specific high-use appliances like your hot water' +
      ' system or pool pump. Controlled load tariffs are generally lower because they are only turned' +
      ' on in off-peak periods. If you have a controlled load, it will be clearly displayed on your bill. ',
  },
  SOLAR_FEED_IN: {
    text: 'Solar Feed-in',
    infoPopoverText:
      'Solar Feed-in Tariffs are a payment made to you for exporting your excess solar energy to' +
      ' the grid.  Like your usage tariffs, the price you are paid may vary depending on the time of day or the' +
      ' amount that is being exported. This information will be clearly displayed on your bill.',
  },
  DISCOUNTS: {
    text: 'Discounts',
    infoPopoverText:
      'Your retailer or the government may offer savings on your bill in the form of a rebate,' +
      ' discount or concession. Common discounts include bundling electricity and gas, low-income concessions,' +
      ' pay-on time discounts and sign up offers. If you receive a %-discount, check whether this applies to your' +
      ' whole bill or just your usage charges. To find the best plan for you, compare plans on our app.',
  },
  DEMAND_CHARGES: {
    text: 'Demand Charges',
    infoPopoverText:
      'Demand charges measure your highest point of electricity usage over a period of time and are ' +
      'applied on top of your usage and supply charges. These charges try to deter the use of multiple high-power' +
      ' appliances during peak periods and are often paired with lower electricity rates. Your demand charge' +
      ' and the associated time window should be displayed on your bill.',
  },
};

function AdditionalRateButton(props: { type: AdditionalRatesAndDiscountsType } & FlexProps) {
  const { type, ...rest } = props;
  const { text, infoPopoverText } = ADDITIONAL_RATE_TYPE_TO_CONFIG[type];
  const { formData } = useTariffFormContext();
  const borderColor = useColorModeValue('#D9D9D9', '#262626');

  const formattedFormDataSummary = useMemo<string | null>(() => {
    switch (type) {
      case 'CONTROLLED_LOAD':
        const controlledLoadFormData = formData.additionalRatesAndDiscounts.controlledLoads;
        if (!controlledLoadFormData) return null;
        let numLoads = 0;
        if (!!controlledLoadFormData.controlledLoad.rate) numLoads++;
        if (controlledLoadFormData.hasControlledLoad2 && !!controlledLoadFormData.controlledLoad2.rate) numLoads++;
        return `${numLoads} load${numLoads > 1 ? 's' : ''}`;
      case 'SOLAR_FEED_IN':
        const feedInFormData = formData.additionalRatesAndDiscounts.solarFeedIn;
        if (!feedInFormData) return null;
        return 'Flat'; // @TODO: update when tiered/tou export rates are implemented
      case 'DISCOUNTS':
        const discountData = formData.additionalRatesAndDiscounts.discounts;
        if (!discountData || !discountData.discounts.length) return null;
        return 'Discounts added'; // @TODO: update when multiple discounts are known
      default:
        return null;
    }
  }, [type, formData]);

  return (
    <Flex
      borderTop="1px solid"
      borderBottom="1px solid"
      borderColor={borderColor}
      color="customBlue.500"
      justify="space-between"
      px={3}
      py={4}
      w={'100%'}
      bg={formattedFormDataSummary ? 'white' : 'backgroundLightMode.500'}
      _dark={{
        bg: formattedFormDataSummary ? 'gray.900' : 'customGray.500',
      }}
      as="button"
      align="center"
      data-has-value={formattedFormDataSummary ? true : undefined}
      {...rest}
    >
      <Flex align="center">
        {!formattedFormDataSummary && <AddIcon w={5} h={5} mr={2} />}
        <Text fontWeight="bold">{text}</Text>
        <InfoPopover
          triggerContainerProps={{
            onClick: (e) => {
              e.stopPropagation();
            },
          }}
        >
          {infoPopoverText}
        </InfoPopover>
      </Flex>

      {formattedFormDataSummary && (
        <Flex align="center" color="primaryBrandingStatic.500">
          <Text>{formattedFormDataSummary}</Text>
          <ChevronRightIcon ml={1} color="#666666" w={5} h={5} />
        </Flex>
      )}
    </Flex>
  );
}

import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { CurrentEnergyRates } from 'clipsal-cortex-types/src/api/api-current-rates';
import { LiveDataSummary } from 'clipsal-cortex-types/src/api/api-live-data';
import { LiveWeatherData } from 'clipsal-cortex-types/src/api/api-weather';
import { useAppVisibility } from 'clipsal-cortex-utils/src/hooks/use-app-visibility';

import { baseApi } from '../../app/services/baseApi';
import { selectSite } from '../site/siteSlice';
import { LIVE_DATA_POLLING_INTERVAL_MS } from './constants';

export const liveDataApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getLiveData: build.query<LiveDataSummary, number>({
      query: (id) => `/v1/sites/${id}/live_data_summary`,
      providesTags: ['LiveData'],
    }),
    getWeather: build.query<LiveWeatherData, number>({
      query: (id) => `/v1/sites/${id}/weather`,
      providesTags: ['Weather'],
    }),
    getEnergyRates: build.query<CurrentEnergyRates, number>({
      query: (id) => `/v1/sites/${id}/current_rates`,
      providesTags: ['CurrentEnergyRates'],
    }),
  }),
});

export const { useGetLiveDataQuery, useGetWeatherQuery, useGetEnergyRatesQuery } = liveDataApi;

const DEFAULT_LIVE_DATA_VALUE: LiveDataSummary = {
  last_updated: '',
  appliances: [],
  solar: 0,
  grid: 0,
  consumption: 0,
  self_powered_fraction: 0,
  blackout: false,
};

export function useLiveData({ shouldPoll = false }: { shouldPoll?: boolean }) {
  const site = useSelector(selectSite);
  const isAppVisible = useAppVisibility();
  const queryResult = useGetLiveDataQuery(site.site_id, {
    pollingInterval: shouldPoll ? LIVE_DATA_POLLING_INTERVAL_MS : undefined,
    skip: !isAppVisible,
  });
  const errorCount = useRef(0);

  useEffect(() => {
    const isPending = queryResult.isFetching || queryResult.isLoading;
    if (!isPending) {
      errorCount.current = queryResult.isError ? errorCount.current + 1 : 0;
    }
  }, [queryResult]);

  // When a user has been viewing the live page after at least one successful request to the live_data_summary endpoint, then
  // if a subsequent request fails, the page should continue to show the power flow animation with old data and keep polling.
  // This behaviour should continue up until 3 subsequent failed requests, and if no success, show the error UI.
  // NOTE: if we have old data, it means the query was initially successful. Hence we can use queryResult?.data as a flag
  const isError = queryResult?.data ? errorCount.current > 2 : queryResult.isError;

  return {
    ...queryResult,
    isError,
    data: queryResult.data ?? DEFAULT_LIVE_DATA_VALUE,
  };
}

const DEFAULT_WEATHER_VALUE: LiveWeatherData = {
  datetime: '',
  condition: '',
  description: '',
  temperature: 0,
  icon: '0',
  id: 0,
  daytime: false,
  city_name: '',
};

export function useWeather() {
  const site = useSelector(selectSite);
  const queryResult = useGetWeatherQuery(site.site_id);

  return {
    ...queryResult,
    data: queryResult.data ?? DEFAULT_WEATHER_VALUE,
  };
}

const DEFAULT_CURRENT_RATES_VALUE: CurrentEnergyRates = {
  import_rate: 0,
  export_rate: 0,
  cl1_rate: 0,
  cl2_rate: 0,
};

export function useCurrentEnergyRates() {
  const site = useSelector(selectSite);
  const isAppVisible = useAppVisibility();
  const queryResult = useGetEnergyRatesQuery(site.site_id, {
    pollingInterval: 60_000,
    skip: !isAppVisible,
  });

  return {
    ...queryResult,
    data: queryResult.data ?? DEFAULT_CURRENT_RATES_VALUE,
  };
}

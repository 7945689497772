import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

export type EVChargerState = {
  isChargingCurrentControlOpen: boolean;
};

const initialState: EVChargerState = {
  isChargingCurrentControlOpen: false,
};

export const evChargerSlice = createSlice({
  name: 'evCharger',
  initialState,
  reducers: {
    updateIsChargingCurrentControlOpen: (state, action: PayloadAction<boolean>) => {
      return { ...state, isChargingCurrentControlOpen: action.payload };
    },
  },
});

export const { updateIsChargingCurrentControlOpen } = evChargerSlice.actions;

export const selectIsChargingCurrentControlOpen = (state: RootState) => {
  return state.evCharger.isChargingCurrentControlOpen;
};

export const { reducer: evChargerReducer } = evChargerSlice;

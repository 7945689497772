import React from 'react';
import { WarningIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Image, Text, useDisclosure } from '@chakra-ui/react';

import schneiderChargeImg from '../../../assets/images/se_charge.png';
import { PageBase } from '../../../common/components/PageBase';
import { TopNav } from '../../../common/components/TopNav';
import { BOTTOM_NAV_HEIGHT } from '../../../common/constants';
import { useBulkPathSegmentReplace } from '../../../common/hooks/use-bulk-path-segment-replace';
import { SupportModal } from '../../support/SupportModal';

export function SetupUnavailableFail() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [start] = useBulkPathSegmentReplace(['start']);

  return (
    <PageBase h="100%">
      <TopNav title={'Device Setup'} backURL={start} />

      <Flex direction={'column'} align="center" justify={'space-between'} h="100%" maxH={800}>
        <Box px={8} mt={4}>
          <Text fontWeight={'bold'}>Unavailable</Text>
          <Text>Please try to connect your app again.</Text>
          <Text>The app couldn't connect to the EV charger, but you can still charge your Electric Vehicle.</Text>
          <Text mt={4}>Contact support to make sure it was set up properly.</Text>
        </Box>
        <Box position={'relative'}>
          <Image src={schneiderChargeImg} alt="Schneider Charge Setup Successful" my={4} w="100%" maxW={200} />
          <WarningIcon position={'absolute'} right={-6} bottom={0} w={16} h={16} color="#F17A6A" />
        </Box>
        <Flex direction={'column'} align="center" justify={'center'} width="100%" mb={BOTTOM_NAV_HEIGHT}>
          <Button
            variant="solid"
            size="md"
            rounded={50}
            colorScheme="dusk100"
            minW={180}
            px={8}
            fontWeight={500}
            py={6}
            mb={6}
            onClick={onOpen}
          >
            Contact Support
          </Button>
        </Flex>
      </Flex>
      {isOpen && <SupportModal isOpen={isOpen} onClose={onClose} />}
    </PageBase>
  );
}

import { ChakraTheme, Colors, DeepPartial, extendTheme, StyleFunctionProps, theme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

import {
  DESKTOP_LARGE_WIDTH_BREAKPOINT,
  DESKTOP_WIDTH_BREAKPOINT,
  MOBILE_WIDTH_BREAKPOINT,
  TABLET_WIDTH_BREAKPOINT,
} from 'clipsal-cortex-utils/src/calculations/viewport-helpers';

import defaultBranding from '../assets/branding/default.json';

export const colors = {
  ...theme.colors,
  ...(defaultBranding.colors as Colors),
  ellipse: {
    50: '#FFC634',
    100: '#FFC634',
    200: '#FFC634',
    300: '#FFC634',
    400: '#FFC634',
    500: '#FFC634',
    600: '#FFC634',
    700: '#FFC634',
    800: '#FFC634',
    900: '#FFC634',
  },
  customBlack: {
    50: '#000000',
    100: '#000000',
    200: '#000000',
    300: '#000000',
    400: '#000000',
    500: '#000000',
    600: '#000000',
    700: '#000000',
    800: '#000000',
    900: '#000000',
  },
  customYellow: {
    500: '#F2B200',
  },
  customPurple: {
    400: '#6b5eff',
    500: '#5041F9',
  },
  customRed: {
    500: '#E53E3E',
  },
  headingGrey: {
    500: '#808080',
  },
  darkModeBorderColor: {
    500: '#E1E1E1',
  },
  noBillsReceivedHeading: {
    500: '#D8D8D8',
  },
  primaryBrandingFaded: {
    50: '#e4fce92b',
    100: '#bff0c92b',
    200: '#98e5a72b',
    300: '#71db852b',
    400: '#4ad0632b',
    500: '#31b74a2b',
    600: '#248e392b',
    700: '#1866272b',
    800: '#0b3d162b',
    900: '#0016022b',
  },
  customGray: {
    50: '#1c2230',
    100: '#1c2230',
    200: '#1c2230',
    300: '#1c2230',
    400: '#1c2230',
    500: '#1c2230',
    600: '#1c2230',
    700: '#1c2230',
    800: '#1c2230',
    900: '#1c2230',
  },
  fixedCostGrey: {
    50: '#6F6F6F',
    100: '#6F6F6F',
    200: '#6F6F6F',
    300: '#6F6F6F',
    400: '#6F6F6F',
    500: '#6F6F6F',
    600: '#6F6F6F',
    700: '#6F6F6F',
    800: '#6F6F6F',
    900: '#6F6F6F',
  },
  fixedCostGreyDark: {
    50: '#c2c2c2',
    100: '#c2c2c2',
    200: '#c2c2c2',
    300: '#c2c2c2',
    400: '#c2c2c2',
    500: '#c2c2c2',
    600: '#c2c2c2',
    700: '#c2c2c2',
    800: '#c2c2c2',
    900: '#c2c2c2',
  },
  customDarkGrey: {
    200: '#dcdcdc',
    500: '#a3a3a3',
    800: '#19191e',
    900: '#131315',
  },
  primaryBrandingStatic: {
    50: '#3DCD58',
    100: '#3DCD58',
    200: '#3DCD58',
    300: '#3DCD58',
    400: '#3DCD58',
    500: '#3DCD58',
    600: '#3DCD58',
    700: '#3DCD58',
    800: '#3DCD58',
    900: '#3DCD58',
  },
  customBlue: {
    50: '#dcf3ff',
    100: '#aed7ff',
    200: '#7dbdff',
    300: '#4aa2ff',
    400: '#1a87ff',
    500: '#006ee6',
    600: '#0055b4',
    700: '#003d82',
    800: '#002551',
    900: '#000d21',
  },
  dusk100: {
    50: '#ccd0cc',
    100: '#c0c4c0',
    200: '#b2b7b2',
    300: '#a4a9a4',
    400: '#747979',
    500: '#2B2E2D',
    600: '#212222',
    700: '#242323',
    800: '#1a1919',
    900: '#0a0a0a',
  },
  dusk050: {
    500: '#9BA0A1',
  },
  dusk010: {
    500: '#EAEBEA',
  },
  dusk005: {
    500: '#F1F1F2',
  },
  slate: {
    500: '#ABBAC3',
  },
  natural: {
    500: '#F4F4F2',
  },
  day: {
    500: '#86B5D1',
  },
  backgroundGrey: {
    500: '#F5F6F8',
  },
  collapseBackgroundColor: {
    500: '#F9F9F9',
  },
  textGrey: {
    500: '#8A8A8D',
  },
  rafModalGrey: {
    500: '#C4C4C4',
  },
  borderGrey: {
    100: '#EFEFEF',
    500: '#1A202C',
  },
  notificationBlue: {
    500: '#6B8DA0',
  },
  whiteSmoke: {
    500: '#F4F4F4',
  },
  seBlack: {
    500: '#111111',
  },
  backgroundLightMode: {
    500: '#ECF2F4',
  },
  peakRateRed: {
    500: '#CE3D1D',
  },
  offPeakGreen: {
    500: '#57BB59',
  },
};

const breakpoints = {
  base: `${MOBILE_WIDTH_BREAKPOINT}px`,
  sm: `${TABLET_WIDTH_BREAKPOINT}px`,
  md: `${DESKTOP_WIDTH_BREAKPOINT}px`,
  lg: `${DESKTOP_LARGE_WIDTH_BREAKPOINT}px`,
  xl: `${DESKTOP_LARGE_WIDTH_BREAKPOINT}px`,
};

const config = {
  initialColorMode: 'system',
};

const styles = {
  global: (props: StyleFunctionProps) => ({
    'html, body': {
      bg: mode('#ECF2F4', '#1c2230')(props),
    },
  }),
};

export const getCustomTheme = (brandingConfig?: Record<string, DeepPartial<ChakraTheme>>) => {
  return extendTheme({
    config,
    colors: {
      ...colors,
      ...brandingConfig?.colors,
    },
    breakpoints,
    styles,
    components: {
      Modal: {
        baseStyle: {
          dialog: {
            _dark: {
              bg: 'gray.900',
            },
          },
        },
      },
      Drawer: {
        baseStyle: {
          dialog: {
            _dark: {
              bg: 'gray.900',
            },
          },
        },
      },
      Popover: {
        baseStyle: {
          popper: {
            // This ensures popovers rendered inside portals twice (e.g. a portal popover inside a modal) display
            // correctly across the app. See https://github.com/chakra-ui/chakra-ui/issues/6794#issuecomment-1372566706
            zIndex: 'popover',
          },
        },
      },
    },
    fonts: {
      heading: 'Roobert',
      body: 'Roobert',
      mono: 'Roobert',
    },
  });
};

export const defaultTheme = getCustomTheme();

import React from 'react';
import { CircularProgressProps } from '@chakra-ui/progress/dist/circular-progress';
import { BoxProps, Center, CircularProgress, Text } from '@chakra-ui/react';

type Props = {
  isFullHeight?: boolean;
  text?: string;
  progressProps?: CircularProgressProps;
} & BoxProps;

export function CenteredLoader({ isFullHeight, text = 'Loading...', progressProps, ...boxProps }: Props) {
  return (
    <Center flexDirection="column" height={isFullHeight ? '100vh' : '100%'} {...boxProps}>
      <Text fontSize="lg" mb={2}>
        {text}
      </Text>
      <CircularProgress isIndeterminate color="primaryBranding.500" {...progressProps} />
    </Center>
  );
}

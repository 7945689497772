import React from 'react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import schneiderChargeImg from '../../../assets/images/se_charge.png';
import { PageBase } from '../../../common/components/PageBase';
import { TopNav } from '../../../common/components/TopNav';
import { BOTTOM_NAV_HEIGHT } from '../../../common/constants';
import { useBulkPathSegmentReplace } from '../../../common/hooks/use-bulk-path-segment-replace';
import { selectSite } from '../../site/siteSlice';

export function SetupSuccess() {
  const navigate = useNavigate();
  const { site_id: siteId } = useSelector(selectSite);
  const [activate] = useBulkPathSegmentReplace(['activate']);

  return (
    <PageBase h="100%">
      <TopNav title={'Device Setup'} backURL={activate} />

      <Flex direction={'column'} align="center" justify={'space-between'} h="100%" maxH={800}>
        <Box px={8} mt={4}>
          <Text fontWeight={'bold'}>Setup Successful</Text>
          <Text>We have set up your Schneider Charge successfully.</Text>
        </Box>
        <Box position={'relative'}>
          <Image src={schneiderChargeImg} alt="Schneider Charge Setup Successful" my={4} w="100%" maxW={200} />
          <CheckCircleIcon position={'absolute'} right={-6} bottom={0} w={16} h={16} color="#57BB59" />
        </Box>
        <Flex direction={'column'} align="center" justify={'center'} width="100%" mb={BOTTOM_NAV_HEIGHT}>
          <Button
            variant="solid"
            size="md"
            rounded={50}
            colorScheme="dusk100"
            minW={180}
            px={8}
            fontWeight={500}
            py={6}
            mb={6}
            onClick={() => navigate(`/site/${siteId}/live`)}
          >
            Done
          </Button>
        </Flex>
      </Flex>
    </PageBase>
  );
}

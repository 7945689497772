import { differenceInMilliseconds, intervalToDuration } from 'date-fns';
import { formatInTimeZone, toZonedTime } from 'date-fns-tz';

import { formatDate } from '../formatting/formatting';

export interface PreciseRangeValueObject {
  years: number;
  months: number;
  weeks: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export type FetchDirection = 'left' | 'right';

export const MS_PER_SECOND = 1000;
export const MS_PER_MINUTE = MS_PER_SECOND * 60;
export const MS_PER_HOUR = MS_PER_MINUTE * 60;
export const MS_PER_DAY = MS_PER_HOUR * 24;

/**
 * Calculates the time difference between two specified dates.
 *
 * @param start - The starting date, to be calculated from
 * @param end - The ending date, to be calculated to
 * @returns The number of days between the two specified dates.
 */
export function calculateDifferenceBetweenDates(start: Date, end: Date): number {
  const utc1 = Date.UTC(start.getFullYear(), start.getMonth(), start.getDate());
  const utc2 = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate());
  return Math.floor((utc2 - utc1) / MS_PER_DAY);
}

/**
 * Retrieves the difference between two provided `Date` objects.
 * The returned object contains values for various time periods, which can be formatted separately.
 *
 * @param start - The starting date, to be calculated from
 * @param end - The ending date, to be calculated to
 */
export function getTimeBetweenDates(start: Date, end: Date): PreciseRangeValueObject {
  const emptyRangeValueObject = {
    years: 0,
    months: 0,
    days: 0,
    weeks: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  const startIsInvalid = !(start instanceof Date && !!start.getDate());
  const endIsInvalid = !(start instanceof Date && !!end.getDate());
  if (startIsInvalid || endIsInvalid) return emptyRangeValueObject;

  const { years, months, days, hours, minutes, seconds, weeks } = intervalToDuration({
    start,
    end,
  });

  return {
    years: years || 0,
    months: months || 0,
    days: days || 0,
    weeks: weeks || 0,
    hours: hours || 0,
    minutes: minutes || 0,
    seconds: seconds || 0,
  };
}

/**
 * Retrieves the date after the provided date object.
 *
 * @param date - The date to retrieve the date after for.
 */
export function getDateAfter(date: Date): Date {
  const newDate = new Date(date);
  // Set the date object as one day later, to fetch data between this 24h period (midnight to midnight).
  newDate.setDate(newDate.getDate() + 1);

  return newDate;
}

/**
 * Parses a date string and returns a date object.
 *
 * @param date - The date string that needs to be converted.
 */
export function convertDateStringToDateObject(date: string): Date {
  const providedDate = date.split('-');
  return new Date(Number(providedDate?.[0]), Number(providedDate?.[1]) - 1, Number(providedDate?.[2]));
}

/**
 * Returns a date for a specified number of days before the provided date object.
 *
 */
export function getDateXDaysAgo(numOfDays: number, date = new Date()) {
  const daysAgo = new Date(date.getTime());
  daysAgo.setDate(date.getDate() - numOfDays);
  return daysAgo;
}

/**
 * Returns a date for a specified number of days after the provided date object.
 *
 */
export function getDateXDaysAhead(numOfDays: number, date = new Date()) {
  const daysAhead = new Date(date.getTime());
  daysAhead.setDate(date.getDate() + numOfDays);
  return daysAhead;
}

/**
 * Returns a start and end date for use as query parameters when fetching data from the /costs api.
 *
 * Is called when the user fetches a new page of costs data.
 *
 * Accounts for which direction the user is moving and always fetches twice the results of the device window size.
 *
 */
export function getNewDatesOnPageChange(
  dateFrom: Date,
  windowSize: number,
  fetchDirection: FetchDirection
): { startDate: string; endDate: string } {
  const startDate = formatDate(dateFrom);
  let endDate: string;

  if (fetchDirection === 'left') {
    const startDate = formatDate(getDateXDaysAgo(windowSize * 2 - 1, dateFrom));
    endDate = formatDate(dateFrom);
    return { startDate, endDate };
  }
  endDate = formatDate(getDateXDaysAhead(windowSize * 2 - 1, dateFrom));
  return { startDate, endDate };
}

/**
 * Returns a start and end date for use as query parameters when fetching data from the /costs api.
 *
 * Is called when the user selects a date from the date picker.
 *
 */

export function getNewDatesOnSelectDate(
  selectedDateString: string,
  windowSize: number,
  timezone: string
): { startDate: string; endDate: string } {
  const selectedDate = convertDateStringToDateObject(formatInTimeZone(selectedDateString, timezone, 'yyyy-MM-dd'));
  const startDate = formatDate(getDateXDaysAgo(windowSize / 2, selectedDate));
  const endDate = formatDate(getDateXDaysAhead(windowSize / 2, selectedDate));
  return { startDate, endDate };
}

/**
 * Returns true if start date is before installation date.
 *
 */
export const checkIfStartDateIsBeforeInstallDate = (startDate: string, monitoring_start: string) =>
  startDate < monitoring_start;

/**
 * Returns true if dates are on the same day.
 *
 */
export function checkIfDatesAreOnSameDay(first: Date, second: Date) {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
}

/**
 * Calculates the time difference between two specified dates
 * and return formatted time difference. e.g. { value: 60, unit: 'secs' }
 *
 * @param start - The starting date, to be calculated from
 * @param end - The ending date, to be calculated to
 * @returns object - The formatted time difference between the two specified dates
 */
export function getFormattedTimeDifference(start: Date, end: Date) {
  const timeDifferenceInMS = differenceInMilliseconds(end, start);

  if (timeDifferenceInMS < MS_PER_MINUTE) {
    const seconds = Math.floor(timeDifferenceInMS / MS_PER_SECOND);
    return { value: seconds, unit: `sec${seconds > 1 ? 's' : ''}` };
  } else if (timeDifferenceInMS < MS_PER_HOUR) {
    const minutes = Math.floor(timeDifferenceInMS / MS_PER_MINUTE);
    return { value: minutes, unit: `min${minutes > 1 ? 's' : ''}` };
  } else if (timeDifferenceInMS < MS_PER_DAY) {
    const hours = Math.floor(timeDifferenceInMS / MS_PER_HOUR);
    return { value: hours, unit: `hour${hours > 1 ? 's' : ''}` };
  } else {
    const days = Math.floor(timeDifferenceInMS / MS_PER_DAY);
    return { value: days, unit: `day${days > 1 ? 's' : ''}` };
  }
}

/**
 * Discerns the first day of the week, for a given day. This function will not modify the `Date` object provided
 * in-place -- it will return a new object.
 * Note: In this context, a week starts on a Monday.
 *
 * @param date - The date to find the first day of the week for.
 * @returns The first day of the week for the given date.
 */
export function getFirstDayOfWeek(date: Date): Date {
  const mutableDate = new Date(date);
  // Gets the day of the week -- if it's Sunday (0), set to 7 for the next calculation
  const day = mutableDate.getDay() || 7;
  if (day !== 1) mutableDate.setHours(-24 * (day - 1));
  return mutableDate;
}

/**
 * Simple function to convert a UTC offset in minutes to an ISO-8601 formatted offset string.
 *
 * @param offsetInMinutes - The offset
 */
export function convertOffsetToISO8601(offsetInMinutes: number) {
  // Calculate hours and minutes
  const hours = Math.floor(Math.abs(offsetInMinutes) / 60);
  const minutes = Math.abs(offsetInMinutes) % 60;

  // Handle positive or negative offsets
  const sign = offsetInMinutes >= 0 ? '+' : '-';

  // Format as "hh:mm"
  return `${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

/**
 * Function to return the current day for the timezone given
 *
 * @param timezone - The timezone
 */
export function getCurrentDayInTimezone(timezone: string) {
  const currentTime = toZonedTime(new Date(), timezone);
  currentTime.setHours(0, 0, 0, 0);
  return new Date(currentTime);
}

/**
 * Calculates difference between the timestamp in minutes .
 * If no end timestamp is provided, it uses current date's timestamp
 *
 * @param {number} startTimestamp represents timestamp of starting date
 * @param {string} timezone represents timezone of the location
 * @param {number} endTimestamp (optional) represents timestamp of ending date
 */
export function getMinuteDifferenceBetweenTimeStamps(
  startTimestamp: number,
  timezone: string,
  endTimestamp?: number
): number | null {
  if (!startTimestamp) return null;
  const startDate = toZonedTime(new Date(startTimestamp * 1000), timezone);
  const endDate = toZonedTime(endTimestamp ? new Date(endTimestamp * 1000) : new Date(), timezone);
  return Math.ceil((endDate.getTime() - startDate.getTime()) / 60000);
}

/**
 * Converts epoch time, passed as either a 10 or 13-digit string, to a Date object.
 * @param epochTime - The epoch time to convert
 * @returns {Date} - The converted Date object
 */
export function convertEpochTimeToDate(epochTime: string): Date {
  if (epochTime.length === 10) {
    return new Date(Number(epochTime) * 1000);
  } else if (epochTime.length === 13) {
    return new Date(Number(epochTime));
  }
  throw new Error('Invalid epoch time');
}

import React, { ReactNode, useEffect, useState } from 'react';
import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { Box, Button, Flex, Heading } from '@chakra-ui/react';

import { Platform } from 'clipsal-cortex-types/src/api';
import { VersionCheckResult } from 'clipsal-cortex-types/src/api/api-version-check-result';
import { useServiceWorker } from 'clipsal-cortex-utils/src/providers/service-worker-provider';

import { postPublic } from '../common/api/api-helpers';
import { ANDROID_PLAY_STORE_URL, APP_VERSION, APPLE_APP_STORE_URL } from '../common/constants';
import { openLinkInCortexApp } from '../utils/native/browser';

interface Props {
  readonly children: ReactNode;
}

export const VersionCheck = ({ children }: Props) => {
  const { isUpdateRequired, onReloadPage } = useServiceWorker();

  // By default show app as network can be slow depending on device
  // And we do no want false update alerts to pop up
  const [showApp, setShowApp] = useState(true);
  const [isStandalone, setIsStandalone] = useState(false);

  useEffect(() => {
    if (
      window.matchMedia('(display-mode: standalone)').matches ||
      ('standalone' in window.navigator && window.navigator['standalone'])
    )
      setIsStandalone(true);
  }, []);

  useEffect(() => {
    /* istanbul ignore next -- @preserve */
    const checkIfNativeUpdateRequired = async () => {
      try {
        const { update_required: updateRequired } = await postPublic<VersionCheckResult>('/v1/version_check', {
          platform: Capacitor.getPlatform().toUpperCase() as Platform,
          version: APP_VERSION,
        });
        setShowApp(!updateRequired);
      } catch (error) {
        console.error(error);
        // When the API call fails, show the app to prevent the user from being stuck
        setShowApp(true);
      }
    };

    /* istanbul ignore if -- @preserve */
    if (Capacitor.isNativePlatform()) {
      checkIfNativeUpdateRequired();

      CapacitorApp.addListener('appStateChange', (state) => {
        if (state.isActive) {
          checkIfNativeUpdateRequired();
        }
      });
    } else if (isUpdateRequired) {
      if (isStandalone) {
        setShowApp(false);
      } else {
        onReloadPage();
      }
    } else {
      setShowApp(true);
    }
  }, [isUpdateRequired, onReloadPage, isStandalone]);

  // Hide the splash screen on app launch
  /* istanbul ignore next -- @preserve */
  useEffect(() => {
    const hideSplashScreen = async () => await SplashScreen.hide();
    if (Capacitor.isNativePlatform()) hideSplashScreen();
  }, []);

  return showApp ? <>{children}</> : <AlertUpdateRequired onReloadPage={onReloadPage} />;
};

interface AlertUpdateRequiredProps {
  onReloadPage: () => void;
}

const AlertUpdateRequired = ({ onReloadPage }: AlertUpdateRequiredProps) => {
  const [showAlert, setShowAlert] = useState(false);

  const handleClick = () => {
    /* istanbul ignore if -- @preserve */
    if (Capacitor.isNativePlatform()) {
      openLinkInCortexApp(Capacitor.getPlatform() === 'ios' ? APPLE_APP_STORE_URL : ANDROID_PLAY_STORE_URL);
    } else {
      onReloadPage();
    }
  };

  // Delay to prevent the toast from showing on initial page load
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box minHeight="100vh">
      {showAlert ? (
        <Flex
          direction={'column'}
          alignItems={'center'}
          borderRadius={10}
          color="white"
          p={3}
          bg="green.500"
          width={'80vw'}
          position={'fixed'}
          left={'50%'}
          bottom={4}
          transform={'translate(-50%, 0)'}
        >
          <Flex mb={2} align={'center'}>
            <Heading mr={1} size={'sm'}>
              A new version of Clipsal Cortex is available
            </Heading>
          </Flex>
          <Button width={'50%'} variant={'outline'} size={'xs'} onClick={handleClick} color="white">
            Update
          </Button>
        </Flex>
      ) : null}
    </Box>
  );
};

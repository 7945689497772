import { useEffect, useRef } from 'react';

/**
 * Custom hook to establish interval timers within functional components.
 *
 * @param callback - The callback invoked on each interval
 * @param delay - The delay for the interval, in MS.
 */
export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return;
    }

    function tick() {
      savedCallback.current && savedCallback.current();
    }

    const id = setInterval(tick, delay);

    return () => clearInterval(id);
  }, [delay]);
}

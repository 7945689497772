import { useSelector } from 'react-redux';

import { selectSite } from '../../features/site/siteSlice';
import { IS_DEMO_LOGIN } from '../constants';

/**
 * An extremely simple hook which is regularly re-used, to discern if a top banner is currently rendered in the app.
 *
 * @returns A boolean, specifying whether a top banner is currently being displayed.
 */
export function useTopBanner() {
  const site = useSelector(selectSite);

  return (
    IS_DEMO_LOGIN ||
    (!!site.site_id && site.has_valid_subscription && (site.hasDeviceFault || site.commissioning_status === 'PENDING'))
  );
}

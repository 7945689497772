import 'react-datepicker/dist/react-datepicker.css';

import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Select,
  useColorModeValue,
} from '@chakra-ui/react';
import { getYear, Locale } from 'date-fns';
import { range } from 'lodash';
import DatePicker, { DatePickerProps, ReactDatePickerCustomHeaderProps, registerLocale } from 'react-datepicker';

import { CalendarIcon } from 'clipsal-cortex-icons/src/custom-icons';
import { MONTHS } from 'clipsal-cortex-utils/src/constants/common-constants';
import { useViewportType } from 'clipsal-cortex-utils/src/hooks/use-viewport-type';

type CommonProps = {
  inputProps?: InputProps & { 'data-testid'?: string }; // Only compatible with default custom input
  inputGroupProps?: InputGroupProps; // Only compatible with default custom input
  dateFormatFn?: (value: string | undefined) => string; // Modify the date input
  showMovePeriodButtons?: boolean;
  showYearPicker?: boolean;
  iconPlacement?: 'right' | 'left';
  locale?: Locale;
  months?: string[];
};

export type CustomDatePickerProps = CommonProps & DatePickerProps;

export function CustomDatePicker({
  inputProps,
  inputGroupProps,
  dateFormatFn,
  showMovePeriodButtons,
  showYearPicker,
  locale,
  iconPlacement = 'left',
  months = MONTHS,
  ...datePickerProps
}: CustomDatePickerProps) {
  const { isDesktopViewport } = useViewportType();
  const isYearView = datePickerProps?.dateFormat === 'yyyy';
  const isMonthView = datePickerProps?.dateFormat === 'MM/yyyy';

  useEffect(() => {
    // if a locale is provided, we register it to date-picker
    locale?.code && registerLocale(locale.code, locale);
  }, [locale]);

  return (
    <DatePicker
      locale={locale}
      withPortal={!isDesktopViewport}
      showPopperArrow={false}
      popperPlacement="bottom-end"
      customInput={
        <DefaultCustomDateInput
          inputProps={inputProps}
          inputGroupProps={inputGroupProps}
          iconPlacement={iconPlacement}
          dateFormatFn={dateFormatFn}
        />
      }
      calendarClassName={useColorModeValue('light-mode-date-picker', 'dark-mode-date-picker')}
      fixedHeight
      disabledKeyboardNavigation
      useWeekdaysShort
      showYearPicker={showYearPicker}
      {...datePickerProps}
      renderCustomHeader={(props) => (
        <CustomDatePickerHeader
          {...props}
          isYearView={isYearView}
          showMovePeriodButtons={showMovePeriodButtons}
          isMonthView={isMonthView}
          showYearPicker={showYearPicker ?? !isYearView}
          minDate={datePickerProps?.minDate}
          months={months}
        />
      )}
    />
  );
}

const DefaultCustomDateInput = React.forwardRef<
  HTMLInputElement,
  {
    // The following 3 properties are typed as optional because they're injected automatically by react-datepicker
    value?: string;
    onClick?: (e: MouseEvent<HTMLInputElement>) => void;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    iconPlacement: 'right' | 'left';
  } & CommonProps
>(({ value, onClick, onChange, inputProps, inputGroupProps, iconPlacement, dateFormatFn = (value) => value }, ref) => {
  return (
    <InputGroup {...inputGroupProps}>
      {iconPlacement === 'left' && (
        <InputLeftElement onClick={onClick}>
          <CalendarIcon boxSize={4} />
        </InputLeftElement>
      )}

      <Input
        isReadOnly
        placeholder={'Select a date'}
        autoComplete="off"
        ref={ref}
        value={dateFormatFn(value)}
        onClick={onClick}
        onChange={onChange}
        {...inputProps}
      />

      {iconPlacement === 'right' && (
        <InputRightElement onClick={onClick}>
          <CalendarIcon boxSize={4} />
        </InputRightElement>
      )}
    </InputGroup>
  );
});

DefaultCustomDateInput.displayName = 'DefaultCustomDateInput';

type CustomDatePickerHeaderProps = ReactDatePickerCustomHeaderProps & {
  minDate?: Date | null;
  isYearView: boolean;
  isMonthView: boolean;
  showMovePeriodButtons?: boolean;
  showYearPicker?: boolean;
  months: string[];
};

function CustomDatePickerHeader({
  isYearView,
  isMonthView,
  minDate,
  showMovePeriodButtons,
  showYearPicker = true,
  months,
  ...props
}: CustomDatePickerHeaderProps) {
  // The following props are injected automatically by react-datepicker
  const {
    date,
    changeMonth,
    changeYear,
    increaseMonth,
    increaseYear,
    decreaseYear,
    decreaseMonth,
    prevMonthButtonDisabled,
    prevYearButtonDisabled,
    nextMonthButtonDisabled,
    nextYearButtonDisabled,
  } = props;

  const [month, setMonth] = useState(date.getMonth());
  const years = range(minDate?.getFullYear() || 2019, getYear(new Date()) + 1, 1);

  return (
    <Flex justifyContent="space-between" data-testid="date-picker-header">
      <Flex>
        {!(isYearView || isMonthView) && (
          <Select
            data-testid="month-dropdown-selector"
            className="month-dropdown-selector"
            value={months[month]}
            onChange={({ target: { value } }) => {
              changeMonth(months.indexOf(value));
              setMonth(months.indexOf(value));
            }}
            icon={<ChevronRightIcon color="#A6A6A6 !important" />}
            border="none"
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option.slice(0, 3).toUpperCase()}
              </option>
            ))}
          </Select>
        )}

        {!isYearView && showYearPicker && (
          <Select
            data-testid="year-dropdown-selector"
            value={date.getFullYear()}
            minW={85}
            onChange={({ target: { value } }) => changeYear(Number(value))}
            icon={<ChevronRightIcon color="#A6A6A6 !important" />}
            border="none"
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
        )}
      </Flex>

      {(!isMonthView || showMovePeriodButtons) && (
        <Flex>
          <IconButton
            data-testid="previous-period-button"
            variant="unstyled"
            icon={<ChevronLeftIcon boxSize={8} color="#A6A6A6" />}
            onClick={() => {
              if (isYearView) {
                decreaseYear();
              } else {
                decreaseMonth();
                setMonth((prevMonth) => {
                  const newMonth = prevMonth - 1;
                  if (newMonth < 0) return 11;
                  return newMonth;
                });
              }
            }}
            isDisabled={isYearView ? prevYearButtonDisabled : prevMonthButtonDisabled}
            aria-label={`Previous ${isYearView ? 'year' : 'month'}`}
          />
          <IconButton
            data-testid="next-period-button"
            variant="unstyled"
            icon={<ChevronRightIcon boxSize={8} color="#A6A6A6" />}
            onClick={() => {
              if (isYearView) {
                increaseYear();
              } else {
                increaseMonth();
                setMonth((prevMonth) => {
                  const newMonth = prevMonth + 1;
                  if (newMonth > 11) return 0;
                  return newMonth;
                });
              }
            }}
            isDisabled={isYearView ? nextYearButtonDisabled : !!nextMonthButtonDisabled}
            aria-label={`Next ${isYearView ? 'year' : 'month'}`}
          />
        </Flex>
      )}
    </Flex>
  );
}

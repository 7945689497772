import React from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { TariffFormData } from '../types';

type Props = {
  onOpen: () => void;
};

export function SeasonConfigurationDialogButton({ onOpen }: Props) {
  const { getValues: getParentValues } = useFormContext<TariffFormData>();

  return (
    <Flex
      w="100%"
      as="button"
      type="button"
      mb={2}
      mt={1}
      px={[3, 3, 3, 5]}
      py={5}
      justify="space-between"
      bg="white"
      _dark={{ bg: 'gray.900' }}
      onClick={onOpen}
      data-testid="configure-seasons-btn"
    >
      <Text fontWeight="bold">Do these rates vary seasonally?</Text>
      <Flex align="center">
        <Text color="primaryBranding.500">{getParentValues('shouldApplySeasons') ? 'Yes' : 'No'}</Text>
        <ChevronRightIcon w={6} h={6} />
      </Flex>
    </Flex>
  );
}

import { useMemo } from 'react';

import { TimeOfUse, TOURate } from '../../rates/time-of-use/types';
import { convertTimeToDate } from '../../rates/time-of-use/utils';
import { MIDNIGHT_TIME } from './constants';
import { DisplayedTimeOfUse, RatesByPeriod } from './types';

const MIDNIGHT = new Date(2021, 0, 1, 0, 0, 0, 0);

/**
 * Converts form data to a structure which resembles what is displayed in the UI (where each "period" has a set of
 * applicable rate times of use).
 *
 * @param allRatesFlattened - An array of flattened `TOURate` objects.
 */
export function useRatesByPeriod(allRatesFlattened: TOURate[]): RatesByPeriod {
  return useMemo<RatesByPeriod>(() => {
    const ratesByPeriod = allRatesFlattened.reduce<RatesByPeriod>(
      (acc, rate, rateIndex) => {
        if (rate.appliesAtAllOtherTimes) {
          // If this rate applies at all other times, insert this "placeholder" UI rate
          const rateApplicableAtAllOtherTimes = {
            fromTime: MIDNIGHT_TIME,
            toTime: MIDNIGHT_TIME,
            appliesAtAllOtherTimes: true,
            rateIndex,
            type: rate.type,
          };
          return {
            weekdays: [...acc.weekdays, rateApplicableAtAllOtherTimes],
            weekends: [...acc.weekends, rateApplicableAtAllOtherTimes],
            publicHolidays: [...acc.publicHolidays, rateApplicableAtAllOtherTimes],
          };
        }

        // Convert times of use which overlap the midnight boundary into two separate times of use, one from the
        // 'from' (start) time til midnight, the other from midnight til the 'to' (end) time
        const normalizeTimeOfUse = (tou: TimeOfUse) => {
          const fromTime = convertTimeToDate(tou.fromTime);
          const toTime = convertTimeToDate(tou.toTime);

          // Any times which go until past midnight need to be "wrapped around" manually.
          if (toTime.getTime() <= fromTime.getTime() && toTime.getTime() !== MIDNIGHT.getTime()) {
            return [
              {
                fromTime: tou.fromTime,
                toTime: MIDNIGHT_TIME,
                appliesAtAllOtherTimes: false,
                rateIndex,
                type: rate.type,
              },
              {
                fromTime: MIDNIGHT_TIME,
                toTime: tou.toTime,
                appliesAtAllOtherTimes: false,
                rateIndex,
                type: rate.type,
              },
            ];
          }

          return [
            {
              fromTime: tou.fromTime,
              toTime: tou.toTime,
              appliesAtAllOtherTimes: false,
              rateIndex,
              type: rate.type,
            },
          ];
        };

        const weekdayTimesOfUse = rate.timesOfUse
          .filter((t) => t.applicablePeriods.find((p) => ['WEEKDAYS', 'EVERYDAY'].includes(p.value)))
          .flatMap<DisplayedTimeOfUse>(normalizeTimeOfUse);
        const weekendTimesOfUse = rate.timesOfUse
          .filter((t) => t.applicablePeriods.find((p) => ['WEEKENDS', 'EVERYDAY'].includes(p.value)))
          .flatMap<DisplayedTimeOfUse>(normalizeTimeOfUse);
        const publicHolidayTimesOfUse = rate.timesOfUse
          .filter((t) => t.applicablePeriods.find((p) => p.value === 'PUBLIC_HOLIDAYS'))
          .flatMap<DisplayedTimeOfUse>(normalizeTimeOfUse);

        return {
          weekdays: [...acc.weekdays, ...weekdayTimesOfUse],
          weekends: [...acc.weekends, ...weekendTimesOfUse],
          publicHolidays: [...acc.publicHolidays, ...publicHolidayTimesOfUse],
        };
      },
      { weekdays: [], weekends: [], publicHolidays: [] }
    );

    Object.values(ratesByPeriod).forEach((ratesForPeriod) => {
      ratesForPeriod.sort((a, b) => {
        const aStartTime = convertTimeToDate(a.fromTime).getTime();
        const bStartTime = convertTimeToDate(b.fromTime).getTime();

        return aStartTime - bStartTime;
      });
    });

    return ratesByPeriod;
  }, [allRatesFlattened]);
}

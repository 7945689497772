import React, { useState } from 'react';
import { Box, Flex, Skeleton, Text, VStack } from '@chakra-ui/react';
import { formatDate } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { useSelector } from 'react-redux';

import { Card } from 'clipsal-cortex-ui/src/components/card/Card';

import { PageBase } from '../../common/components/PageBase';
import { useViewportType } from '../../common/hooks/use-viewport-type';
import { COMMON_BASE_PAGE_X_PADDING } from '../bills/tariffs/constants';
import { selectSite } from '../site/siteSlice';
import { ActivityLogTopNav } from './ActivityLogTopNav';
import { useActivityLog } from './dashboardApi';
import { useGetActivityLogIcon } from './use-activity-log-icon';

export const ActivityLog = () => {
  const { timezone } = useSelector(selectSite);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { isDesktopViewport } = useViewportType();
  const { ActivityLog, isLoading: isActivitiesLoading, isFetching } = useActivityLog(selectedDate);
  const isLoading = isActivitiesLoading || isFetching;
  const getActivityLogIcon = useGetActivityLogIcon();

  const getActivityContents = () => {
    if (isLoading) {
      return Array.from(Array(4).keys()).map((key) => (
        <Skeleton key={key} h={88} rounded={isDesktopViewport ? 10 : 0} w="100%" />
      ));
    }

    if (!ActivityLog.length)
      return (
        <Text textAlign={'center'} mt={4}>
          No activities found for this date.
        </Text>
      );

    return ActivityLog.map(({ siteDeviceId, eventType, deviceType, circuitType, deviceName, date, manufacturerId }) => {
      const formattedTime = formatDate(toZonedTime(date, timezone), 'h:mm a');

      return (
        <Card
          key={siteDeviceId + date}
          w="100%"
          rounded={isDesktopViewport ? 10 : 0}
          mb={isDesktopViewport ? 0 : 1}
          shadow={isDesktopViewport ? 'md' : 'none'}
        >
          <Flex>
            {getActivityLogIcon(deviceType, circuitType, manufacturerId)}

            <Box mr={8} ml={2} minW={28}>
              <Text fontWeight={500} noOfLines={1}>
                {deviceName}
              </Text>
              <Text>{formattedTime}</Text>
            </Box>
            <Box ml="auto" my="auto" color={'primaryBrandingStatic.500'} fontWeight={600}>
              {eventType}
            </Box>
          </Flex>
        </Card>
      );
    });
  };

  return (
    <PageBase px={COMMON_BASE_PAGE_X_PADDING}>
      <ActivityLogTopNav
        isLoading={isLoading}
        selectedDate={selectedDate}
        onSelectDate={(date) => setSelectedDate(date)}
      />
      <VStack
        mt={isDesktopViewport ? 20 : 32}
        w="100%"
        px={isDesktopViewport ? 4 : 0}
        spacing={isDesktopViewport ? 2 : 0}
      >
        {getActivityContents()}
      </VStack>
    </PageBase>
  );
};

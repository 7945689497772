import React from 'react';
import {
  Box,
  BoxProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverContentProps,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { ImInfo } from 'react-icons/im';

type InfoPopoverProps = React.PropsWithChildren<{
  triggerContainerProps?: BoxProps;
  infoIconProps?: BoxProps & { 'data-testid': string };
  popoverContentProps?: PopoverContentProps;
}>;

export function InfoPopover({ triggerContainerProps, popoverContentProps, infoIconProps, children }: InfoPopoverProps) {
  return (
    <Popover isLazy trigger={'hover'}>
      <PopoverTrigger>
        <Box
          onClick={() => {
            // @FIXME: This is a noop, but sometimes the icon won't be clickable in the DOM. Needs investigation.
          }}
          {...triggerContainerProps}
          as="span"
        >
          <Box
            color="customBlue.500"
            display="inline"
            ml={1}
            mb={'-3px'}
            cursor={'pointer'}
            as={ImInfo}
            {...infoIconProps}
          />
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent maxWidth="400px" p={2} rounded={20} zIndex={4} {...popoverContentProps}>
          <PopoverArrow />
          <PopoverBody textAlign="center">{children}</PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

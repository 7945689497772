import React, { useMemo } from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';
import Highcharts, { DashStyleValue, Options, PointOptionsObject, YAxisPlotLinesOptions } from 'highcharts';
import Chart from 'highcharts-react-official';
import highchartsAccessibility from 'highcharts/modules/accessibility';

import { CHART_HEIGHT_PX } from './time-of-use/constants';

highchartsAccessibility(Highcharts);

type Props = {
  series: Highcharts.SeriesColumnOptions[];
  height?: number;
};

export function ColumnChart({ series, height = CHART_HEIGHT_PX }: Props) {
  const textColor = useColorModeValue('black', 'white');

  // All except the last series have an upper limit, display as a line in the chart
  const { plotLines } = series
    .slice(0, series.length - 1)
    .reduce<{ plotLines: YAxisPlotLinesOptions[]; cumulativeSum: number }>(
      (acc, stack) => {
        const data = stack.data?.[0] as PointOptionsObject;
        if (!data.custom?.upperLimitKWh) return acc;

        acc.cumulativeSum += data.y as number;

        acc.plotLines.push({
          color: textColor,
          width: 1,
          value: acc.cumulativeSum,
          dashStyle: 'LongDash' as DashStyleValue,
          zIndex: 50,
          label: {
            style: {
              color: textColor,
              fontSize: '14px',
            },
          },
        });

        return acc;
      },
      { plotLines: [], cumulativeSum: 0 }
    );

  const options = useMemo((): Options => {
    return {
      title: {
        text: undefined,
      },
      chart: {
        backgroundColor: 'transparent',
        type: 'column',
        height,
        style: {
          fontFamily: 'inherit',
        },
      },
      yAxis: {
        reversedStacks: false,
        title: {
          text: undefined,
        },
        height: '100%',
        offset: 0,
        labels: {
          enabled: false,
        },
        plotLines,
        gridLineWidth: 0,
      },
      xAxis: {
        visible: false,
        title: {
          text: undefined,
        },
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          groupPadding: 0,
        },
        series: {
          enableMouseTracking: false,
          animation: false,
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      series,
    };
  }, [height, plotLines, series]);

  // Charts overflowing the parent container seems to be a common issue in Highcharts.
  // see: https://stackoverflow.com/a/34334676
  return (
    <Box maxW="100vw" position={'relative'} height={`${height}px`}>
      <Box position={'absolute'} width="100%">
        <Chart highcharts={Highcharts} options={options} />
      </Box>
    </Box>
  );
}

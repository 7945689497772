import React, { ComponentType, useEffect, useState } from 'react';
import { WarningIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
} from '@chakra-ui/react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { TariffType } from 'clipsal-cortex-types/src/api/api-tariffs-v2';

import { ConfigureFlatRate } from '../flat/ConfigureFlatRate';
import { ConfigureTieredRate } from '../tiered/ConfigureTieredRate';
import { ConfigureTOURate } from '../time-of-use/ConfigureTOURate';
import { TariffFormData } from '../types';
import { SeasonFieldError } from './types';

// Due to the significant difference between "traditional" rate structures and real-time rate structures, the latter
// is separated into a different component structure entirely. Thus, we can rely on this tariff type never being used
// with this component.
type NonRealTimeTariffType = Exclude<TariffType, 'REAL_TIME'>;

const RATE_TYPE_TO_FORM: Record<Exclude<TariffType, 'REAL_TIME'>, ComponentType<{ seasonIndex: number }>> = {
  TOU: ConfigureTOURate,
  TIERED: ConfigureTieredRate,
  FLAT: ConfigureFlatRate,
};

type Props = {
  tariffType: NonRealTimeTariffType;
};

export function SeasonFieldArray({ tariffType }: Props) {
  const [selectedSeasonIndex, setSelectedSeasonIndex] = useState<number | null>(0);
  const {
    control,
    formState: { errors },
  } = useFormContext<TariffFormData>();
  const { fields: seasonFields } = useFieldArray({
    control,
    name: 'seasons',
  });
  const hasSeasons = seasonFields.length > 1;
  const SeasonForm = RATE_TYPE_TO_FORM[tariffType];

  // Automatically open the accordion item with an error when a submission occurs
  useEffect(() => {
    if (errors?.seasons?.length) {
      const firstFoundErrorIndex = (errors.seasons as SeasonFieldError[]).findIndex((error) => !!error);

      if (firstFoundErrorIndex !== -1) {
        setSelectedSeasonIndex(firstFoundErrorIndex);
      }
    }
  }, [errors]);

  return (
    <Box>
      {/* Don't display the season accordion if there is only one season */}
      {seasonFields.length > 1 ? (
        <Accordion allowMultiple index={selectedSeasonIndex !== null ? [selectedSeasonIndex] : []}>
          {seasonFields.map((seasonField, seasonIndex) => {
            const errorsForSeason = errors?.seasons?.[seasonIndex];

            return (
              <AccordionItem key={seasonField.id}>
                <AccordionButton
                  data-testid={`season-${seasonIndex}-accordion-btn`}
                  onClick={() => setSelectedSeasonIndex(seasonIndex === selectedSeasonIndex ? null : seasonIndex)}
                  px={0}
                  py={4}
                >
                  <Box as="span" flex="1" textAlign="left">
                    <Flex align="center">
                      <Heading mr={2} size="sm">
                        Import Rates {hasSeasons ? `(${seasonField.name})` : ''}
                      </Heading>
                      {!!errorsForSeason && (
                        <WarningIcon
                          data-testid={`season-${seasonIndex}-validation-error`}
                          w={5}
                          h={5}
                          color="customRed.500"
                        />
                      )}
                    </Flex>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel px={0} pb={4}>
                  <SeasonForm seasonIndex={seasonIndex} />
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      ) : (
        <Box px={0} pb={4}>
          <SeasonForm seasonIndex={0} />
        </Box>
      )}
    </Box>
  );
}

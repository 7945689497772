import React, { PropsWithChildren, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { useNetworkStatus } from 'clipsal-cortex-ui/src/components/NetworkStatusProvider';

import { selectUser } from '../../../features/user/userSlice';

export const RouteAuthCheck = ({ children }: PropsWithChildren) => {
  const { isLoggedIn } = useSelector(selectUser);
  const { isOnline } = useNetworkStatus();
  const toast = useToast({ isClosable: true });

  useEffect(() => {
    /* istanbul ignore if -- @preserve */
    if (!isOnline) {
      // close toast if it exists
      toast.close('offline');
      toast({
        id: 'offline',
        status: 'warning',
        title: 'You are offline',
        description: 'Please check your internet connection.',
        duration: null,
      });
    }
  }, [isOnline, toast]);

  return isLoggedIn ? (
    <>{children}</>
  ) : (
    <Navigate to="/login" state={{ originalPath: `${window.location.pathname}${window.location.search}` }} />
  );
};

import { useSelector } from 'react-redux';

import { AirConState, SensiboDevice, SensiboDeviceDetail } from 'clipsal-cortex-types/src/api/api-sensibo-devices';
import { useAppVisibility } from 'clipsal-cortex-utils/src/hooks/use-app-visibility';

import { baseApi } from '../../../app/services/baseApi';
import { selectSite } from '../../site/siteSlice';

export const sensiboApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    authoriseSensibo: build.mutation<void, { siteID: number; authorisationCode: string }>({
      query: ({ siteID, authorisationCode }) => {
        return {
          url: `/v1/sites/${siteID}/sensibo_authorisation?authorisation_code=${authorisationCode}`,
          method: 'POST',
          body: {},
        };
      },
    }),
    deleteSensiboAuthorisation: build.mutation<void, number>({
      query: (siteID) => {
        return {
          url: `/v1/sites/${siteID}/sensibo_authorisation`,
          method: 'DELETE',
          body: {},
        };
      },
    }),
    getSensiboDevices: build.query<SensiboDevice[], number>({
      query: (siteID) => `/v1/sites/${siteID}/sensibo_devices`,
      providesTags: (_, __, siteID) => [{ type: 'SensiboDevices', siteID }],
    }),
    getSensiboDevice: build.query<SensiboDeviceDetail, { siteID: number; deviceID: number }>({
      query: ({ siteID, deviceID }) => `/v1/sites/${siteID}/sensibo_devices/${deviceID}`,
      providesTags: (_, __, { siteID, deviceID }) => [{ type: 'SensiboDevice', siteID, deviceID }],
    }),
    postSensiboDevice: build.mutation<void, { siteID: number; deviceID: number; body: AirConState }>({
      query: ({ siteID, deviceID, body }) => {
        return {
          url: `/v1/sites/${siteID}/sensibo_devices/${deviceID}`,
          method: 'POST',
          body: { ac_state: body },
        };
      },
      invalidatesTags: (_, __, { siteID, deviceID }) => [{ type: 'SensiboDevice', siteID, deviceID }],
    }),
  }),
});

export const {
  useAuthoriseSensiboMutation,
  useDeleteSensiboAuthorisationMutation,
  useGetSensiboDevicesQuery,
  useGetSensiboDeviceQuery,
  usePostSensiboDeviceMutation,
} = sensiboApi;

export const useSensiboDevices = () => {
  const { site_id: siteID, integrations } = useSelector(selectSite);
  const { data, isLoading, isError, refetch, isFetching } = useGetSensiboDevicesQuery(siteID, {
    skip: !integrations.sensibo,
  });
  return { devices: data || [], isLoading, isError, refetch, isFetching };
};

const SENSIBO_DEVICE_POLLING_INTERVAL = 5000;

export const useSensiboDevice = (deviceId: number | null) => {
  const { site_id: siteID } = useSelector(selectSite);
  const isAppVisible = useAppVisibility();
  const { data, isLoading, isError, isFetching, refetch } = useGetSensiboDeviceQuery(
    { siteID, deviceID: deviceId || 0 },
    { skip: !deviceId || !isAppVisible, pollingInterval: SENSIBO_DEVICE_POLLING_INTERVAL }
  );
  return {
    deviceData: data,
    isLoading,
    isError,
    isFetching,
    refetch,
  };
};

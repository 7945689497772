import '../build/polyfills';

import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, Flex, Heading } from '@chakra-ui/react';
import { useRegisterSW } from 'virtual:pwa-register/react';

interface ContextProps {
  isUpdateRequired: boolean;
  onReloadPage: () => void;
}

const ServiceWorkerContext = createContext({} as ContextProps);

// eslint-disable-next-line react-refresh/only-export-components
export const useServiceWorker = () => useContext(ServiceWorkerContext);

export function ServiceWorkerProvider({ children }: React.PropsWithChildren) {
  const [showApp, setShowApp] = useState(true);
  const {
    needRefresh: [isUpdateRequired],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r) {
      // eslint-disable-next-line prefer-template
      console.log('SW Registered: ' + r);
    },
    onRegisterError(error) {
      console.log('SW registration error', error);
    },
  });

  useEffect(() => {
    const onError = ({ error }: { error: Error }) => {
      if (error.message.includes('Failed to fetch dynamically imported module')) {
        setShowApp(false);
      }
    };

    window.addEventListener('error', onError);
    return () => {
      window.removeEventListener('error', onError);
    };
  }, []);

  const onReloadPage = useCallback(async () => {
    await updateServiceWorker(true);
    window.location.reload();
  }, [updateServiceWorker]);

  const value = useMemo(() => ({ isUpdateRequired, onReloadPage }), [isUpdateRequired, onReloadPage]);

  return (
    <ServiceWorkerContext.Provider value={value}>
      {showApp ? children : <Alert onReloadPage={onReloadPage} />}
    </ServiceWorkerContext.Provider>
  );
}

function Alert({ onReloadPage }: { onReloadPage: () => void }) {
  return (
    <Box minHeight="100vh">
      <Flex
        direction={'column'}
        alignItems={'center'}
        borderRadius={10}
        color="white"
        p={3}
        bg="green.500"
        width={'80vw'}
        position={'fixed'}
        left={'50%'}
        bottom={4}
        transform={'translate(-50%, 0)'}
      >
        <Flex mb={2} align={'center'}>
          <Heading mr={1} size={'sm'}>
            Something went wrong. Please reload the App.
          </Heading>
        </Flex>
        <Button width={'50%'} variant={'outline'} size={'xs'} onClick={onReloadPage} color="white">
          Reload
        </Button>
      </Flex>
    </Box>
  );
}

import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

import { Card } from 'clipsal-cortex-ui/src/components/card/Card';

import { useViewportType } from '../../../common/hooks/use-viewport-type';

export function FormContainer({ children, ...rest }: BoxProps) {
  const { isDesktopViewport } = useViewportType();
  const FormContainer = isDesktopViewport ? Card : Box;

  return (
    <FormContainer bg="white" _dark={{ bg: 'gray.900' }} py={5} px={[3, 3, 3, 5]} mt={1} {...rest}>
      {children}
    </FormContainer>
  );
}

import React from 'react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Flex, Heading, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { openLinkInCortexApp } from '../../../utils/native/browser';
import { SupportModal } from '../../support/SupportModal';
import { ProfilePageBase } from '../ProfilePageBase';

export const Contact = () => {
  const background = useColorModeValue('white', 'gray.900');
  const borderColor = useColorModeValue('gray.300', 'black');
  const hoverColor = useColorModeValue('whiteAlpha.700', 'black');
  const { isOpen: isSupportDialogOpen, onOpen: onOpenSupportDialog, onClose: onCloseSupportDialog } = useDisclosure();
  const navigate = useNavigate();

  const contactLinkConfig = [
    {
      title: 'Live Page is not working',
      dataTestId: 'live-page-not-working-btn',
      onClick: () =>
        openLinkInCortexApp(
          'https://support.clipsalcortex.com/hc/en-us/articles/7245710193295-How-to-power-cycle-your-Cortex-meter'
        ),
    },
    {
      title: 'Issue with solar/battery installation',
      dataTestId: 'installation-issue-btn',
      onClick: () => navigate('installation-issues'),
    },
    {
      title: 'Contact Support',
      dataTestId: 'support-center-btn',
      onClick: onOpenSupportDialog,
    },
  ];

  return (
    <ProfilePageBase title={'Contact & Support'}>
      {contactLinkConfig.map((page) => (
        <Box bg={background} _hover={{ bg: hoverColor }} key={page.title}>
          <Flex
            borderBottom="1px solid"
            borderColor={borderColor}
            justify={'space-between'}
            align={'center'}
            py={8}
            px={6}
            cursor={'pointer'}
            onClick={page.onClick}
            data-testid={page.dataTestId}
          >
            <Heading fontSize={18}>{page.title}</Heading>
            <ChevronRightIcon w={'26px'} h={'26px'} />
          </Flex>
        </Box>
      ))}

      {isSupportDialogOpen && <SupportModal isOpen={isSupportDialogOpen} onClose={onCloseSupportDialog} />}
    </ProfilePageBase>
  );
};

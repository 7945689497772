import React, { Fragment, PropsWithChildren, useState } from 'react';
import { ChevronUpIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  FlexProps,
  Grid,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { ImInfo } from 'react-icons/im';

import { RPCPlan, RPCTariffDisplaySection } from 'clipsal-cortex-types/src/api/api-rpc';
import { formatDollarsCents } from 'clipsal-cortex-utils/src/formatting/number-formatting';

import { openLinkInCortexApp } from '../../../../utils/native/browser';
import { RetailerImage } from '../../RetailerImage';
import { getDifferenceBetweenCosts, rpcDisplayLabelTypeToIcon } from './helpers';
import { DMOInfoContents } from './MobileViewPlanDetail';

interface TableCellProps extends PropsWithChildren, FlexProps {}

function TableHeaderCell({ children, ...styleProps }: TableCellProps) {
  return (
    <Flex
      px={2}
      py={4}
      borderBottom="1px solid"
      borderColor={'dusk005.500'}
      _dark={{
        borderColor: 'textGrey.500',
      }}
      justify={'flex-start'}
      align={'center'}
      {...styleProps}
    >
      {children}
    </Flex>
  );
}

function TableBodyCell({ children, ...styleProps }: TableCellProps) {
  return (
    <Flex
      px={2}
      direction={'column'}
      py={3}
      borderBottom="1px solid"
      borderColor={'dusk005.500'}
      _dark={{
        borderColor: 'textGrey.500',
      }}
      justify={'center'}
      align={'flex-start'}
      {...styleProps}
    >
      {children}
    </Flex>
  );
}

export interface Props {
  plans: RPCPlan[];
  currentBillCost: number;
  totalNumPlans: number;
  isExpanded: boolean;
  setExpanded: (isExpanded: boolean) => void;
}

export function DesktopPlansList({ plans, totalNumPlans, currentBillCost, isExpanded, setExpanded }: Props) {
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const tableBackgroundColor = useColorModeValue('backgroundGrey.500', 'gray.900');
  const collapseBackgroundColor = useColorModeValue('collapseBackgroundColor.500', 'gray.900');
  const cols = [
    { heading: 'Provider' },
    { heading: 'Energy Plan' },
    { heading: 'Plan Details' },
    { heading: 'Estimated Bill' },
    // The last column is just an action column, with no heading.
    { heading: '' },
  ];

  return (
    <>
      <Grid
        my={4}
        px={4}
        pb={4}
        rounded={20}
        border="1px solid"
        borderColor={'customDarkGrey.200'}
        _dark={{
          borderColor: 'textGrey.500',
        }}
        templateColumns={'0.75fr 1.5fr 1fr 1fr 0.75fr'}
        data-testid="plan-list"
      >
        <>
          {cols.map(({ heading }, i) => (
            <TableHeaderCell
              justify={i === 0 || i === cols.length - 2 ? 'center' : 'flex-start'}
              backgroundColor={tableBackgroundColor}
              ml={i === 0 ? -4 : undefined}
              borderTopLeftRadius={i === 0 ? 20 : undefined}
              mr={i === cols.length - 1 ? -4 : undefined}
              borderTopRightRadius={i === cols.length - 1 ? 20 : undefined}
              key={`plan-data-header-col-${i}`}
            >
              <Heading size={'sm'}>{heading}</Heading>
            </TableHeaderCell>
          ))}
        </>

        {plans.map((plan, i) => {
          const leftColumnValue = plan.tariff.display_section.slice(0, 4);
          const rightColumnValue = plan.tariff.display_section.slice(4, plan.tariff.display_section.length);

          return (
            <Fragment key={`plan-data-row-${i}`}>
              <TableBodyRow
                totalNumPlans={totalNumPlans}
                currentBillCost={currentBillCost}
                expandedIndex={expandedIndex}
                setExpandedIndex={setExpandedIndex}
                plan={plan}
                planIndex={i}
              />

              {/* Details expand section */}
              <Box backgroundColor={collapseBackgroundColor} gridColumn={'1 / 6'}>
                <Collapse in={expandedIndex === i} data-testid="details-expand-section">
                  <SimpleGrid gridColumnGap={8} py={4} px={8} columns={2}>
                    <Flex mb={6} align={'center'} gridColumn={'1 / 3'}>
                      <Text mr={3} fontSize={'sm'}>
                        {plan.default_offer_message}
                      </Text>

                      <DMOInfoPopover />
                    </Flex>

                    <Flex direction={'column'}>
                      <ExpandDetailsList displaySections={leftColumnValue} />
                    </Flex>

                    <Flex direction={'column'}>
                      <ExpandDetailsList displaySections={rightColumnValue} />
                    </Flex>
                  </SimpleGrid>

                  {plan.tariff.url && (
                    <Flex direction={'column'} align={'center'}>
                      <Divider mt={4} borderColor={'white'} borderWidth={1} />

                      <Button
                        onClick={() => {
                          openLinkInCortexApp(plan.tariff.url as string);
                        }}
                        my={1}
                        variant={'ghost'}
                        color={'customBlue.500'}
                      >
                        Read the fine print
                      </Button>
                    </Flex>
                  )}
                </Collapse>
              </Box>

              {/* Display a full-width heading to indicate other plans */}
              {i === 2 && plans.length > 3 && (
                <Heading
                  pt={5}
                  pb={4}
                  borderBottom="1px solid"
                  borderColor={'dusk005.500'}
                  _dark={{
                    borderColor: 'textGrey.500',
                  }}
                  alignSelf={'center'}
                  size={'md'}
                  gridColumn={'1 / 6'}
                >
                  Other plans
                </Heading>
              )}
            </Fragment>
          );
        })}
      </Grid>

      <Flex justify={'center'}>
        <Button
          onClick={() => {
            setExpanded(!isExpanded);
          }}
          rounded={20}
          width={'20%'}
          colorScheme={'dusk100'}
          data-testid={isExpanded ? 'show-less-btn' : 'show-more-btn'}
        >
          {isExpanded ? 'Show less' : 'Show more'}
        </Button>
      </Flex>
    </>
  );
}

interface TableBodyRowProps {
  plan: RPCPlan;
  totalNumPlans: number;
  expandedIndex: number;
  setExpandedIndex: (expandedIndex: number) => void;
  currentBillCost: number;
  planIndex: number;
}

function TableBodyRow({
  plan,
  totalNumPlans,
  expandedIndex,
  setExpandedIndex,
  currentBillCost,
  planIndex,
}: TableBodyRowProps) {
  return (
    <>
      <TableBodyCell data-testid="plan-list-row">
        <>
          <Flex alignSelf={'center'} color={'textGrey.500'}>
            <Text fontWeight={'bolder'}>{planIndex + 1}&nbsp;</Text> <Text>/ {totalNumPlans} plans</Text>
          </Flex>
          <RetailerImage id={plan.tariff.retailer.id} size={'md'} name={plan.tariff.retailer.name} />
        </>
      </TableBodyCell>

      <TableBodyCell>
        <>
          <Heading size={'sm'}>{plan.tariff.retailer.name}</Heading>
          <Text>{plan.tariff.name}</Text>
        </>
      </TableBodyCell>

      <TableBodyCell>
        <>
          {plan.tariff.display_labels.map((displayLabel, i) => (
            <Flex align={'center'} color={'textGrey.500'} key={`rpc-display-label-${i}`}>
              {rpcDisplayLabelTypeToIcon[displayLabel.type]} {displayLabel.value}
            </Flex>
          ))}
        </>
      </TableBodyCell>

      <TableBodyCell>
        <>
          <Heading alignSelf={'center'} size={'lg'}>
            {formatDollarsCents(plan.cost)}
          </Heading>
          {getDifferenceBetweenCosts(currentBillCost, plan.cost)}
        </>
      </TableBodyCell>

      <TableBodyCell>
        <Button
          variant={'ghost'}
          p={2}
          onClick={() => {
            setExpandedIndex(planIndex === expandedIndex ? -1 : planIndex);
          }}
          alignSelf={'center'}
          color={'customBlue.500'}
          data-testid={`view-plan-details-button-${planIndex}`}
        >
          <Flex align={'center'}>
            <Heading size={'sm'}>View plan details </Heading>{' '}
            <ChevronUpIcon
              transform={planIndex === expandedIndex ? undefined : 'rotate(-180deg)'}
              transition={'all 300ms ease-in-out'}
              cursor={'pointer'}
              w={'26px'}
              h={'26px'}
            />
          </Flex>
        </Button>
      </TableBodyCell>
    </>
  );
}

interface ExpandDetailsListProps {
  displaySections: RPCTariffDisplaySection[];
}

function ExpandDetailsList({ displaySections }: Readonly<ExpandDetailsListProps>) {
  return (
    <>
      {displaySections.map((section, i) => (
        <Box mb={3} key={`display-section-${i}`}>
          <Heading mb={2} size={'sm'}>
            {section.name}
          </Heading>

          {section.values.map((displayValue, j) => {
            return (
              <SimpleGrid my={2} columns={2} fontSize={'sm'} key={`display-section-value-${j}`}>
                <Text mr={2}>{displayValue.name}</Text>
                <Box>
                  <Text fontWeight={'bolder'} textAlign={'right'} ml={2}>
                    {displayValue.value}
                  </Text>

                  {displayValue.condition && <Text fontWeight={'bolder'}>{displayValue.condition}</Text>}
                </Box>
              </SimpleGrid>
            );
          })}

          <Divider
            borderColor={'customDarkGrey.200'}
            _dark={{
              borderColor: 'textGrey.500',
            }}
            borderWidth={1}
          />
        </Box>
      ))}
    </>
  );
}

function DMOInfoPopover() {
  return (
    <Popover trigger={'hover'}>
      <PopoverTrigger>
        <Box>
          <Box cursor={'pointer'} as={ImInfo} color={'customBlue.500'} size="22px" />
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent maxWidth="400px" rounded={20}>
          <PopoverArrow />
          <PopoverBody>
            <DMOInfoContents />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

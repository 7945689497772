import { Preferences as Storage } from '@capacitor/preferences';
import { Auth } from 'aws-amplify';

export interface CachedSession {
  token: string;
  accessToken: string;
  refreshToken: string;
  expiration: number; // Unix timestamp in seconds
}

/* istanbul ignore next -- @preserve */
export async function getCachedSession(): Promise<CachedSession | null> {
  const { value } = await Storage.get({ key: 'cachedSession' });
  return value ? JSON.parse(value) : null;
}

/**
 * Fetches the current session from Amplify Auth and updates the local cache.
 * In a monorepo setup, Amplify does not work properly when placed in a shared folder.
 * To avoid issues, Amplify Auth must be passed explicitly as a parameter.
 */
/* istanbul ignore next -- @preserve */
export async function getCurrentAuthSession(AmplifyAuth: typeof Auth): Promise<CachedSession> {
  let session: CachedSession | null = null;

  if (navigator.onLine) {
    try {
      // Fetch fresh session from Amplify when online
      const awsSession = await AmplifyAuth.currentSession();
      const idToken = awsSession.getIdToken();
      const accessToken = awsSession.getAccessToken();
      const refreshToken = awsSession.getRefreshToken();

      const newSession: CachedSession = {
        token: idToken.getJwtToken(),
        accessToken: accessToken.getJwtToken(),
        refreshToken: refreshToken.getToken(),
        expiration: idToken.getExpiration(),
      };

      // Update local cache
      await Storage.set({ key: 'cachedSession', value: JSON.stringify(newSession) });
      session = newSession;
    } catch (error) {
      console.error('Error fetching current session:', error);
      // Fall back to the cached session if available
      session = await getCachedSession();
    }
  } else {
    // Offline: use cached session if available
    session = await getCachedSession();
  }

  if (!session) {
    throw new Error('No session available');
  }

  return session;
}

import React from 'react';
import { Button, CloseButton, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import JoyRide, { CallBackProps, STATUS, Step, TooltipRenderProps } from 'react-joyride';
import { useSelector } from 'react-redux';

import { Card } from 'clipsal-cortex-ui/src/components/card/Card';
import { calculateDifferenceBetweenDates } from 'clipsal-cortex-utils/src/calculations/date-utils';

import { selectSite } from '../../features/site/siteSlice';

const Tooltip = ({ index, isLastStep, size, step, primaryProps, tooltipProps, skipProps }: TooltipRenderProps) => {
  const target = step.target as string;
  return (
    <Card data-testid={`${target.substring(1)}`} rounded={20} bgColor={'#5041F9'} color={'#fff'} mb={0}>
      <Flex justify={'space-between'}>
        <Heading pt={1} size={'sm'} maxWidth={'280px'}>
          {step.title}
        </Heading>
        <CloseButton {...skipProps} _hover={{ bg: '#6b5eff' }} id={'closeTour'} />
      </Flex>
      <Text mt={4} {...tooltipProps} maxWidth={'280px'} whiteSpace={'pre-wrap'}>
        {step.content}
      </Text>
      <VStack m={0} align={'flex-start'} mt={4}>
        {size > 1 && <Text>{`Step ${index + 1} of ${size}`}</Text>}
        <Button
          data-testid={`${target.substring(1)}-btn`}
          {...primaryProps}
          variant="outline"
          _hover={{ bg: '#6b5eff' }}
          color={'#fff'}
          rounded={20}
          w={'40%'}
        >
          {isLastStep ? 'Got it!' : 'Next'}
        </Button>
      </VStack>
    </Card>
  );
};

interface TourSteps {
  tourSteps: Step[];
  localStorageFlag: string;
}

export const TourPopover = ({ tourSteps, localStorageFlag }: TourSteps) => {
  const site = useSelector(selectSite);
  const startDate = new Date(site.monitoring_start);
  const timeSinceOwned = calculateDifferenceBetweenDates(startDate, new Date());
  const isDismissed = localStorage.getItem(localStorageFlag);
  const shouldShowTour = !isDismissed && timeSinceOwned < 30;

  const handleDismiss = (data: CallBackProps) => {
    const { status } = data;
    if (STATUS.FINISHED.includes(status) || STATUS.SKIPPED.includes(status)) {
      localStorage.setItem(localStorageFlag, 'true');
    }
  };

  // Enable scrolling on the usage and bills tour due to placement issues
  let disableScrolling = true;
  tourSteps.forEach((step) => {
    const target = step.target as string;
    const shouldScrollTo = target.includes('.tour-usage') || target === '.tour-bills-consumption-only-step';
    if (shouldScrollTo) disableScrolling = false;
  });

  return (
    <>
      {shouldShowTour && (
        <JoyRide
          callback={handleDismiss}
          steps={tourSteps}
          tooltipComponent={Tooltip}
          disableScrolling={disableScrolling}
          floaterProps={{
            disableAnimation: true,
            disableFlip: true,
          }}
          styles={{
            options: {
              arrowColor: '#5041F9',
            },
          }}
        />
      )}
    </>
  );
};

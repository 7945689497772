import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { SWITCH_CONFIGURABLE_METER_MODELS } from 'clipsal-cortex-utils/src/constants/common-constants';

import { selectSite } from '../../features/site/siteSlice';

export const useSiteHasSwitchingMeter = () => {
  const { devices } = useSelector(selectSite);
  return useMemo(() => {
    return !!devices.find((d) => SWITCH_CONFIGURABLE_METER_MODELS.includes(d.model));
  }, [devices]);
};

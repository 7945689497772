import { useMemo } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';

import { getViewportType, ViewportType } from 'clipsal-cortex-utils/src/calculations/viewport-helpers';

import { VIEWPORT_TYPE_TO_FONT_SIZES } from '../chart-constants';
import { IS_RUNNING_CYPRESS_TESTS } from '../constants';

interface useViewportTypeInterface {
  viewportType: ViewportType;
  fontSizes: {
    selected: string;
    normal: string;
  };
  isDesktopViewport: boolean;
}

export const useViewportType = (): useViewportTypeInterface => {
  const viewportType: ViewportType =
    useBreakpointValue(
      {
        base: 'mobile',
        sm: 'tablet',
        md: 'desktop',
        lg: 'desktop-lg',
        xl: 'desktop-lg',
      },
      { ssr: IS_RUNNING_CYPRESS_TESTS }
    ) || getViewportType(window.innerWidth);

  // this hook is called everwhere in the app, so worth memoizing
  return useMemo(() => {
    const fontSizes = VIEWPORT_TYPE_TO_FONT_SIZES[viewportType];
    const isDesktopViewport = ['desktop-lg', 'desktop'].includes(viewportType);
    return { viewportType, fontSizes, isDesktopViewport };
  }, [viewportType]);
};

import React from 'react';
import { Alert, AlertIcon, Box, Button, Center, Heading, useDisclosure, useToast } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import { PageBase } from '../../../../../common/components/PageBase';
import { RouteChangeBlocker } from '../../../../../common/components/RouteChangeBlocker';
import { TopNav } from '../../../../../common/components/TopNav';
import { useViewportType } from '../../../../../common/hooks/use-viewport-type';
import { selectSite } from '../../../../site/siteSlice';
import { COMMON_BASE_PAGE_X_PADDING, MAX_DOLLARS_PER_KWH_VALUE } from '../../constants';
import { FormContainer } from '../../FormContainer';
import { useTariffFormContext } from '../../tariff-form-context';
import { customIsEqual } from '../../utils';
import { GSTInclusionCheckbox } from '../common/GSTInclusionCheckbox';
import { SeasonConfigurationDialogButton } from '../common/SeasonConfigurationDialogButton';
import { SeasonConfigurationFormDialog, SeasonFormData } from '../common/SeasonConfigurationFormDialog';
import { SeasonFieldArray } from '../common/SeasonFieldArray';
import { EMPTY_SEASON_TEMPLATE, FLAT_FORM_DEFAULT_VALUE } from './constants';
import { FlatTariffFormData } from './types';

const flatFormSchema = yup.object().shape({
  seasons: yup.array().of(
    yup.object().shape({
      rate: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .min(0)
        .max(MAX_DOLLARS_PER_KWH_VALUE, `Must be less than $${MAX_DOLLARS_PER_KWH_VALUE}`)
        .typeError('This field is required'),
    })
  ),
});

export function FlatTariffForm() {
  const { tariffId } = useParams<{ tariffId: string }>();
  const site = useSelector(selectSite);
  const { isDesktopViewport } = useViewportType();
  const {
    formData: { flatRates },
    onUpdateFormData,
  } = useTariffFormContext();
  const defaultValues = flatRates ?? FLAT_FORM_DEFAULT_VALUE;
  const form = useForm<FlatTariffFormData>({
    resolver: yupResolver(flatFormSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isSubmitting },
  } = form;
  const { onOpen, onClose, isOpen } = useDisclosure();
  const navigate = useNavigate();
  const toast = useToast({ isClosable: true, duration: 5_000 });
  const [search] = useSearchParams();
  const backURL = search.get('backURL');

  async function handleSaveSeasons(values: SeasonFormData) {
    reset({
      shouldApplySeasons: values.shouldApplySeasons,
      seasons: values.seasons.map((s, seasonIndex) => {
        const fromMonth = s.fromDate.getMonth();
        const fromDate = s.fromDate.getDate();
        const toMonth = s.toDate.getMonth();
        const toDate = s.toDate.getDate();

        return {
          ...EMPTY_SEASON_TEMPLATE,
          seasonIndex: seasonIndex,
          name: s.name,
          fromMonth,
          fromDate,
          toMonth,
          toDate,
        };
      }),
    });
  }

  function handleSubmissionError() {
    toast({
      title: 'Invalid rates',
      description: 'Check that all required fields are filled out',
      status: 'error',
    });
  }

  async function handleSubmitFlatRateForm(flatRates: FlatTariffFormData) {
    onUpdateFormData((p) => ({ ...p, flatRates }));
    navigate(`/site/${site.site_id}/bills/tariffs/${tariffId}/review`);
  }

  function checkForUnsavedChanges() {
    if (isSubmitting) return false;
    return !customIsEqual(defaultValues, getValues());
  }

  return (
    <RouteChangeBlocker onChangeRoute={checkForUnsavedChanges}>
      <PageBase px={COMMON_BASE_PAGE_X_PADDING}>
        <FormProvider {...form}>
          <TopNav
            backURL={backURL ?? `/site/${site.site_id}/bills/tariffs/${tariffId}/basic-details`}
            title="Flat Rate Configuration"
          />

          <Box px={5}>
            <Alert status="info" variant="left-accent">
              <AlertIcon />
              This will take about a minute. Please make sure you have your bill with you for reference.
            </Alert>
          </Box>

          <Heading ml={2} mt={3} size="sm">
            IMPORT RATES
          </Heading>

          <SeasonConfigurationDialogButton onOpen={onOpen} />
          <SeasonConfigurationFormDialog isOpen={isOpen} onClose={onClose} onSubmit={handleSaveSeasons} />

          <Box
            onSubmit={handleSubmit(handleSubmitFlatRateForm, handleSubmissionError)}
            data-testid="flat-rates-form"
            as="form"
          >
            <FormContainer>
              {isDesktopViewport && (
                <Heading mb={2} size="md">
                  Configure Flat Rates
                </Heading>
              )}

              <GSTInclusionCheckbox />

              <SeasonFieldArray tariffType="FLAT" />
            </FormContainer>

            <Center mt={6}>
              <Button
                data-testid="submit-flat-tariff-form"
                type="submit"
                w={['75%', '75%', '50%']}
                rounded={20}
                colorScheme="dusk100"
              >
                Next
              </Button>
            </Center>
          </Box>
        </FormProvider>
      </PageBase>
    </RouteChangeBlocker>
  );
}

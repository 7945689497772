import React from 'react';
import { Link, ListItem, OrderedList, useDisclosure } from '@chakra-ui/react';

import { PAGE_SPACING_PER_VIEWPORT } from '../../../common/constants';
import { SupportModal } from '../../support/SupportModal';
import { ProfilePageBase } from '../ProfilePageBase';

export function InstallationIssues() {
  const { isOpen: isSupportDialogOpen, onOpen: onOpenSupportDialog, onClose: onCloseSupportDialog } = useDisclosure();

  return (
    <ProfilePageBase title={'Installation Issues'} containerProps={{ px: PAGE_SPACING_PER_VIEWPORT }}>
      <OrderedList>
        <ListItem data-testid="contact-installer-copy" pb={2}>
          Please contact your solar installer
        </ListItem>
        <ListItem data-testid="contact-clipsal-copy">
          If your solar installer is Clipsal Solar, please{' '}
          <Link data-testid="open-support-btn" onClick={onOpenSupportDialog} color="customBlue.500">
            click here
          </Link>
        </ListItem>
      </OrderedList>

      {isSupportDialogOpen && (
        <SupportModal defaultRoute="submitTicket" isOpen={isSupportDialogOpen} onClose={onCloseSupportDialog} />
      )}
    </ProfilePageBase>
  );
}

import React from 'react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { Box, Button, Heading, useDisclosure, useToast, VStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AlertDialogModal } from 'clipsal-cortex-ui/src/components/AlertDialogModal';

import { useReduxDispatch } from '../../../app/store';
import { PageBase } from '../../../common/components/PageBase';
import { TopNav } from '../../../common/components/TopNav';
import { useViewportType } from '../../../common/hooks/use-viewport-type';
import { selectSite, updateSiteData } from '../../site/siteSlice';
import { useDeleteAmberSiteMutation } from './amberApi';
import { AmberConnectionForm } from './AmberConnectionForm';

export const AmberIntegration = () => {
  const { integrations, site_id: siteId } = useSelector(selectSite);
  const { isDesktopViewport } = useViewportType();
  const isConnected = integrations.amber;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteAmberSite, { isLoading: isDeletingAmberSite }] = useDeleteAmberSiteMutation();
  const toast = useToast();
  const dispatch = useReduxDispatch();
  const [search] = useSearchParams();
  const backURL = search.get('backURL') ?? `/site/${siteId}/profile`;
  const navigate = useNavigate();

  const handleDeleteAmberIntegration = async () => {
    try {
      await deleteAmberSite(siteId).unwrap();
      onClose();
      dispatch(updateSiteData({ integrations: { ...integrations, amber: false } }));
      toast({
        title: 'Amber Integration Deleted!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
      toast({
        title: 'Error Deleting Amber Integration',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  return (
    <PageBase px={[0, 0, 0, 20, '20vw']}>
      <TopNav title={'Amber Integration'} backURL={backURL} shouldDisplayTitleOnDesktop />

      <Box
        bg="white"
        _dark={{ bg: 'gray.900' }}
        rounded={isDesktopViewport ? 20 : undefined}
        py={5}
        px={[3, 3, 3, 5]}
        mt={1}
        data-testid="amber-integration-container"
      >
        {isConnected ? (
          <VStack data-testid="delete-amber-connection-screen" minH={400}>
            <Heading size={'md'}>You have already integrated with Amber.</Heading>
            <CheckCircleIcon color="green.500" w={['50%', '50%', '15%']} h={['50%', '50%', '15%']} my={10} />
            <Button
              w={['75%', '75%', '50%']}
              mb={2}
              rounded={20}
              colorScheme="red"
              isLoading={isDeletingAmberSite}
              onClick={onOpen}
              data-testid="delete-amber-connection-button"
            >
              Delete Amber Integration
            </Button>

            <Button
              w={['75%', '75%', '50%']}
              rounded={20}
              mb={2}
              colorScheme="dusk100"
              onClick={() => navigate(backURL)}
              data-testid="inline-back-btn"
              variant="outline"
            >
              Back{!search.get('backURL') && ' to Profile'}
            </Button>
          </VStack>
        ) : (
          <AmberConnectionForm />
        )}
      </Box>
      <AlertDialogModal
        header="Are you sure you want to delete your Amber integration?"
        subHeader=" "
        isOpen={isOpen}
        onClose={onClose}
        confirmButtonTextColor={'customRed.500'}
        isConfirming={isDeletingAmberSite}
        onConfirm={handleDeleteAmberIntegration}
      />
    </PageBase>
  );
};

import { SubscriptionOptions } from '@reduxjs/toolkit/src/query/core/apiState';
import { useSelector } from 'react-redux';

import { SiteDeviceSchedule } from 'clipsal-cortex-types/src/api/api-switch-schedule';
import { Switch } from 'clipsal-cortex-types/src/api/api-ww-switch';

import { baseApi } from '../../app/services/baseApi';
import { get } from '../../common/api/api-helpers';
import { useSiteHasSwitchingMeter } from '../../common/hooks/use-site-has-switching-meter';
import { selectSite } from '../site/siteSlice';

// Note: most of these endpoints use a custom `queryFn` to batch update/create/delete requests, because individual
// endpoints for each at a large scale causes constant cache invalidation, and constant calls to the retrieve endpoint.
export const schedulerApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSwitches: build.query<Switch[], number>({
      query: (siteId) => `/v1/sites/${siteId}/switches`,
      providesTags: ['Switches'],
    }),
    updateSwitch: build.mutation<Switch, { switchId: number; smartSwitch: Partial<Switch> }>({
      query: ({ switchId, smartSwitch }) => {
        return {
          url: `/v1/switches/${switchId}`,
          method: 'PATCH',
          body: smartSwitch,
        };
      },
      invalidatesTags: ['Switches'],
    }),
    getSiteDeviceSchedules: build.query<SiteDeviceSchedule[], number>({
      query: (siteDeviceId) => `/v1/site_devices/${siteDeviceId}/schedules`,
      providesTags: (_, __, siteDeviceId) => [{ type: 'SiteDeviceSchedules', siteDeviceId }],
    }),
    getCombinedSiteDeviceSchedules: build.query<Record<number, SiteDeviceSchedule[]>, number[]>({
      queryFn: async (siteDeviceIds) => {
        const siteDeviceSchedules = await Promise.all(
          siteDeviceIds.map((siteDeviceId) => get<SiteDeviceSchedule[]>(`/v1/site_devices/${siteDeviceId}/schedules`))
        );

        return {
          data: siteDeviceIds.reduce((obj, key, index) => {
            obj[key] = siteDeviceSchedules[index];
            return obj;
          }, {} as Record<number, SiteDeviceSchedule[]>),
        };
      },
      providesTags: (_, __, arg) => [
        ...arg.map((siteDeviceId) => ({
          siteDeviceId,
          type: 'SiteDeviceSchedules' as const,
        })),
      ],
    }),
    putSiteDeviceSchedules: build.mutation<
      SiteDeviceSchedule[],
      { siteDeviceId: number; schedules: SiteDeviceSchedule[]; deleteMissingObjects: boolean }
    >({
      query: ({ siteDeviceId, schedules, deleteMissingObjects }) => {
        return {
          url: `/v1/site_devices/${siteDeviceId}/schedules?delete_missing_objects=${deleteMissingObjects}`,
          method: 'PUT',
          body: schedules,
        };
      },
      invalidatesTags: ['SiteDeviceSchedules'],
    }),
  }),
});

export const {
  useGetSwitchesQuery,
  useUpdateSwitchMutation,
  useGetSiteDeviceSchedulesQuery,
  useGetCombinedSiteDeviceSchedulesQuery,
  usePutSiteDeviceSchedulesMutation,
} = schedulerApi;

export function useGetSwitches(options?: SubscriptionOptions & { skip: boolean }) {
  const site = useSelector(selectSite);
  const { data: switches, ...rest } = useGetSwitchesQuery(site.site_id, options);
  return { switches: switches ?? [], ...rest };
}

export function useGetSiteSwitches() {
  const siteHasSwitchingMeter = useSiteHasSwitchingMeter();
  // Note: Don't call this endpoint at all if the site doesn't have supported hardware
  const { switches, isLoading: isSwitchesLoading } = useGetSwitches({
    skip: !siteHasSwitchingMeter,
  });

  return {
    switches,
    isSwitchesLoading,
  };
}

export function useGetSiteDeviceSchedules(siteDeviceId: number) {
  const { data: schedules, ...rest } = useGetSiteDeviceSchedulesQuery(siteDeviceId);
  return { schedules: schedules ?? [], ...rest };
}

export function useGetCombinedSiteDeviceSchedules(siteDeviceIds: number[]) {
  const { data: schedules, ...rest } = useGetCombinedSiteDeviceSchedulesQuery(siteDeviceIds);
  return { schedules: schedules ?? [], ...rest };
}
